import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
//To establish theme settings in the web

// color design tokens export

//SE DEFINE LA FUNCIÓN TOKENS QUE SE EXPORTA PARA SU USO EN TODA LA APP
//mode represents light or dark mode
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        //Tailwind Shades (plugin) to create all the shades of the same color
        grey: {
          100: "#ffffff",
          250: "#F7F7F7",
          150: "#E7E7E8",
          200: "#F7F7F7",
          250: "#D7D7D8",
          300: "#D7D7D8",
          400: "#8f8f93",
          500: "#7F7F83",
          600: "#4b4c54",
          700: "#2f2f34",
          800: "#121214",
          900: "#000000",
        },
        primary: {
          50: "#eeecff",
          100: "#a8a0ef",
          200: "#9f96ed",
          300: "#9086ea",
          400: "#7063e3",
          500: "#4535dc",
          600: "#3625d0",
          700: "#2d1fad",
          800: "#281c9c",
          900: "#24198e",
        },
        // grey: {
        //   100: "#fff",
        //   200: "#c2c2c2",
        //   300: "#a3a3a3",
        //   400: "#858585",
        //   500: "#666666",
        //   600: "#525252",
        //   700: "#3d3d3d",
        //   800: "#292929",
        //   900: "#141414",
        // },
        // primary: {
        //   100: "#d0d1d5",
        //   200: "#a1a4ab",
        //   300: "#727681",
        //   400: "#1d1d29 ",
        //   500: "#11121e",
        //   600: "#101624",
        //   700: "#0c101b",
        //   800: "#080b12",
        //   900: "#040509",
        // },
        secondary: {
          100: "#FFFFFF",
          200: "#FCFDF3",
          300: "#F9FBE7",
          400: "#F6F9DB",
          500: "#F3F7CF",
          600: "#F0F5C4",
          700: "#EDF3B8",
          800: "#EAF1AC",
          900: "#E7EFA0",
          1000: "#E4EC94",
          1100: "#E1EA88",
          1200: "#DEE87C",
          1300: "#DBE670",
          1400: "#D8E465",
          1500: "#D5E259",
          1600: "#D2E04D",
          1700: "#CFDE41",
          1800: "#CCDC35"
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#ffffff",
          150: "#F7F7F7",
          250: "#F7F7F7",
          175: "#e8e8e8",
          200: "#D7D7D8",
          300: "#BFBFC4",
          400: "#A6A6AD",
          500: "#7f7f83",
          600: "#4b4c54",
          700: "#2f2f34",
          800: "#121214",
          900: "#000000",
        },

        primary: {
          50: "#eeecff",
          100: "#a8a0ef",
          200: "#9f96ed",
          300: "#9086ea",
          400: "#7063e3",
          500: "#4535dc",
          600: "#3625d0",
          700: "#2d1fad",
          800: "#281c9c",
          900: "#24198e",
        },
        // grey: {
        //   100: "#141414",
        //   200: "#292929",
        //   300: "#3d3d3d",
        //   400: "#525252",
        //   500: "#666666",
        //   600: "#858585",
        //   700: "#a3a3a3",
        //   800: "#c2c2c2",
        //   900: "#e0e0e0",
        // },
        // primary: {
        //   100: "#040509",
        //   200: "#080b12",
        //   300: "#0c101b",
        //   400: "#f2f0f0", // manually changed
        //   500: "#141b2d",
        //   600: "#1F2A40",
        //   700: "#727681",
        //   800: "#a1a4ab",
        //   900: "#d0d1d5",
        // },
        secondary: {
          100: "#FFFFFF",
          200: "#FCFDF3",
          300: "#F9FBE7",
          400: "#F6F9DB",
          500: "#F3F7CF",
          600: "#F0F5C4",
          700: "#EDF3B8",
          800: "#EAF1AC",
          900: "#E7EFA0",
          1000: "#E4EC94",
          1100: "#E1EA88",
          1200: "#DEE87C",
          1300: "#DBE670",
          1400: "#D8E465",
          1500: "#D5E259",
          1600: "#D2E04D",
          1700: "#CFDE41",
          1800: "#CCDC35"
        },
        
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.secondary[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.grey[100],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.secondary[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              // default: colors.primary[50],
              default: colors.grey[100],
              
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = (variant) => {
  const [mode, setMode] = useState(variant === 0 ? "dark" : "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};