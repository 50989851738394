import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import BigButton from "../Buttons/BigButton";
import { TopText } from "./TopText";

const TitleHero = ({ variant, setOpen }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animation, setAnimation] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth, scrollToContact } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent:
                screenSize !== "xs" && screenSize !== "sm" ? "left" : "center",
            }}
          >
            {screenSize !== "xs" && screenSize !== "sm" && (
              <TopText text="Aprovecha el potencial de la IA" />
            )}
            <Typography
              lineHeight={"120%"}
              component="h1"
              sx={{
                fontSize: { xs: "2rem", md: "4rem" },
                fontWeight: { xs: "650", md: "750" },
                color: colors.grey[900],
                whiteSpace: "normal",
                overflow: "visible",
                fontFamily: "Inter",
                textAlign:
                  screenSize !== "xs" && screenSize !== "sm"
                    ? "center"
                    : "center",
              }}
            >
              Impulsa tu{" "}
              <span style={{ color: colors.primary[500] }}>agencia </span>
              creando Asistentes Virtuales con{" "}
              <span style={{ color: colors.primary[500] }}>IA </span>para tus
              clientes
            </Typography>
            <Typography
              fontSize={
                screenSize !== "xs" && screenSize !== "sm" ? "1.5rem" : "1rem"
              }
              lineHeight={"1.35"}
              component="h2"
              sx={{
                p: { xs: "0rem", sm: "0 3rem", md: "0 8rem", lg: "0 12rem" },

                marginTop: { xs: "1rem", md: "2rem" },
                color: colors.grey[500],
                textAlign:
                  screenSize !== "xs" && screenSize !== "sm"
                    ? "center"
                    : "center",
                fontFamily: "Inter",
                fontWeight: "400",
              }}
            >
              Crea asistentes IA para tus clientes, genera ingresos recurrentes
              y detecta mejoras en su negocio gracias a las conversaciones con
              los clientes.
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "2rem", md: "4rem" }, // Espacio entre el texto y los botones
                width: "100%",
                display: "flex",
                justifyContent:
                  screenSize !== "xs" && screenSize !== "sm"
                    ? "left"
                    : "center",
              }}
            >
              <Box
                sx={{
                  width:
                    screenSize !== "xs" && screenSize !== "sm"
                      ? "100%"
                      : "100%",
                  display: "flex", // Usamos flexbox para alinear los botones en línea
                  flexDirection: "row",
                  gap: "1rem", // Espacio entre los botones
                  justifyContent: "center", // Centrar los botones en todos los tamaños de pantalla
                }}
              >
                {isMobile ? null : (
                  <BigButton
                    label="Contáctanos"
                    variant="secondary"
                    onClick={() => setOpen(true)}
                  />
                )}

                <BigButton
                  label="Solicitar Demo Gratis"
                  variant="primary"
                  onClick={() => setOpen(true)}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* } */}
      </Grid>
    </Box>
  );
};

export default TitleHero;
