import { Box, Button, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import backgroundImage from "../../Data/Assets/background_demo.png";
import laptopImage from "../../Data/Assets/demo_laptop.png";
import SmallButton from "../Buttons/SmallButton";

const DemoCard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "1rem",
        padding: "0",
        width: "100%",
        height: { xs: "auto", md: "464px" },
        outline: "1px solid #E0E0E1", // Define el borde exterior
        outlineOffset: "0px", // Separa el borde del box
        fontFamily: "Inter, sans-serif",
      }}
    >
      <Grid container alignItems="center" sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={7}
          lg={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              padding: { xs: "2rem 2rem", lg: "5rem 3rem" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize={isMobile ? "1.5rem" : "2rem"}
              lineHeight={1.3}
              sx={{ fontFamily: "Inter, sans-serif" }}
            >
              Cierra{" "}
              <span style={{ color: colors.primary[500] }}>más ventas</span> con{" "}
              <span style={{ color: colors.primary[500] }}>demos</span> rápidas
              y personalizadas para tus clientes
            </Typography>

            <Typography
              fontSize={isMobile ? "0.875rem" : "1rem"}
              lineHeight={1.6}
              sx={{
                fontFamily: "Inter, sans-serif",
                marginTop: "2rem",
                marginBottom: "2rem",
                color: colors.grey[500],
              }}
            >
              <strong>Muestra a tus clientes</strong> el valor de los asistentes
              virtuales en minutos.{" "}
              <strong>Crea una demo funcional en menos de 30 minutos</strong> y
              sorprende a tus clientes con resultados reales, acelerando el
              cierre de ventas.
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <SmallButton
              label="Ver ejemplo de demo"
              variant={"primary"}
              onClick={() => {
                window.location.href =
                  "https://byneural.ai/asistente-personalizado/?id=byneural_K5";
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          lg={5}
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={{ height: "100%", display: { xs: "none", md: "flex" } }}
        >
          <Box
            component="img"
            src={laptopImage}
            alt="Demo Laptop"
            sx={{
              height: { xs: "60%", sm: "70%", md: "90%", lg: "110%" },
              width: "auto",
              transform: {
                xs: "translateY(-16px)",
                sm: "translateY(-16px) translateX(-32px)",
                md: "translateY(-16px) translateX(-64px)",
                lg: "translateY(-16px) translateX(-64px)",
              }, // Esto sube la imagen 32px
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DemoCard;
