import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import { Title } from "../Texts/Title";
import { PrimaryText } from "../Texts/PrimaryText";

import AparienciaImageXS from "../../Data/Assets/Images/PlataformaXS/Apariencia.png";
import BaseConocimientoImageXS from "../../Data/Assets/Images/PlataformaXS/Base conocimiento.png";
import EstadisticasImageXS from "../../Data/Assets/Images/PlataformaXS/Estadísticas.png";
import RecomendacionesImageXS from "../../Data/Assets/Images/PlataformaXS/Recomendaciones.png";
import WorkflowsImageXS from "../../Data/Assets/Images/PlataformaXS/Workflows.png";

import AparienciaImage from "../../Data/Assets/Images/PlataformaXL/Apariencia.png";
import BaseConocimientoImage from "../../Data/Assets/Images/PlataformaXL/Base Conocimiento.png";
import EstadisticasImage from "../../Data/Assets/Images/PlataformaXL/Estadísticas.png";
import InformesImage from "../../Data/Assets/Images/PlataformaXL/Informes.png";
import WorkflowsImage from "../../Data/Assets/Images/PlataformaXL/Workflows.png";

import {
  PiBookOpen,
  PiChartLineUp,
  PiFileText,
  PiPaintBrush,
} from "react-icons/pi";

import { PiNetwork } from "react-icons/pi";

export const PlatformSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tabs = [
    { label: "Workflows", image: WorkflowsImage, icon: <PiNetwork /> },
    {
      label: "Base conocimiento",
      image: BaseConocimientoImage,
      icon: <PiBookOpen />,
    },
    {
      label: "Estadísticas",
      image: EstadisticasImage,
      icon: <PiChartLineUp />,
    },
    { label: "Informes", image: InformesImage, icon: <PiFileText /> },
    { label: "Apariencia", image: AparienciaImage, icon: <PiPaintBrush /> },
  ];

  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const tabRefs = useRef([]);
  const underlineRef = useRef(null);

  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleTabClick = (index) => {
    if (index !== selectedTab) {
      setSelectedTab(index);
    }
  };

  useEffect(() => {
    if (underlineRef.current && tabRefs.current[selectedTab]) {
      const tab = tabRefs.current[selectedTab];
      const tabRect = tab.getBoundingClientRect();
      const containerRect = tab.parentElement.getBoundingClientRect();
      underlineRef.current.style.left = `${
        tabRect.left - containerRect.left
      }px`;
      underlineRef.current.style.width = `${tabRect.width}px`;
    }
  }, [selectedTab]);

  // ---------------- Para el carusel en móvil --------------------------

  const itemsData = [
    {
      id: 1,
      title: "Workflows",
      icon: <PiNetwork />, // Representa flujos de trabajo
      imageUrl: WorkflowsImageXS,
    },
    {
      id: 2,
      title: "Base Conocimiento",
      icon: <PiBookOpen />, // Representa contenido educativo
      imageUrl: BaseConocimientoImageXS,
    },
    {
      id: 3,
      title: "Estadísticas",
      icon: <PiChartLineUp />, // Representa datos o análisis
      imageUrl: EstadisticasImageXS,
    },
    {
      id: 4,
      title: "Recomendaciones",
      icon: <PiFileText />, // Representa sugerencias o ideas
      imageUrl: RecomendacionesImageXS,
    },
    {
      id: 5,
      title: "Apariencia",
      icon: <PiPaintBrush />, // Representa personalización estética
      imageUrl: AparienciaImageXS,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionDirection, setTransitionDirection] = useState("none");

  // Cambio automático de ítem cada 3s
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  // Función para cambiar manualmente al ítem "index"
  const handleIndicatorClick = (index) => {
    setTransitionDirection(index > currentIndex ? "left" : "right");
    setCurrentIndex(index);
  };

  // Avanza al siguiente ítem
  const handleNext = () => {
    setTransitionDirection("left");
    setCurrentIndex((prevIndex) =>
      prevIndex === itemsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Ítem actual
  const currentItem = itemsData[currentIndex];

  // Estilos generales inline
  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      margin: "0 auto",
      paddingTop: "1rem",
      paddingBottom: "6rem",
      position: "relative",
    },
    cardContainer: {
      width: "100%",
      backgroundColor: "transparent",
      borderRadius: "1.25rem",
      maxWidth: "35rem",
      overflow: "hidden",
      position: "relative",
    },
    carouselContainer: {
      display: "flex",
      width: "100%",
      overflow: "hidden",
      position: "relative",
    },
    carouselTrack: {
      display: "flex",
      transition: "transform 0.6s ease-in-out",
      transform: `translateX(-${currentIndex * 100}%)`,
    },
    imageWrapper: {
      flex: "0 0 100%",
      width: "100%",
      aspectRatio: "1 / 1", // Mantiene la proporción cuadrada
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cardImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "1rem",
    },
    titleContainerBase: {
      backgroundColor: colors.grey[900],
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      gap: "0.5rem",
    },
    cardIcon: {
      fontSize: "1.25rem",
      color: colors.grey[100],
    },
    cardTitle: {
      fontSize: "1rem",
      margin: 0,
      color: colors.grey[100],
      fontFamily: "Inter, sans-serif",
    },
    indicatorsContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "0.5rem",
      marginTop: "2rem",
    },
    indicatorBase: {
      width: "60px",
      height: "2px",
      backgroundColor: colors.grey[600],
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    indicatorActive: {
      backgroundColor: colors.grey[100],
    },
  };

  const slideClass =
    transitionDirection === "left"
      ? "slide-left"
      : transitionDirection === "right"
      ? "slide-right"
      : "";

  // Para el fade-in del título e icono
  const fadeClass = "fade-in";

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: isMobile ? "4rem" : "6rem",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: `${pagewidth + 200}px`,
        }}
      >
        <Grid item xs={12}>
          <Box width="100%" display="flex" justifyContent="center">
            {" "}
            <Stack direction="row">
              <Title
                title={"Descubre nuestra plataforma"}
                customColor={colors.grey[100]}
              />
            </Stack>
          </Box>
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: "1rem",
            }}
          >
            <Box maxWidth="65rem" p="0rem 0rem 1rem 0rem">
              <PrimaryText
                customColor={colors.grey[300]}
                fontSize={"1rem"}
                center
                text={
                  "Todo lo que tu agencia necesita para crear asistentes virtuales que venden y fidelizan."
                }
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sx={{ width: "100%", marginTop: "1rem", }}>
          {/* Contenedor para centrar tabs e imagen */}
          {isMobile ? (
            <div style={styles.wrapper}>
              {/* Inyectamos las keyframes y reglas de animación como un bloque de estilos */}
              <style>
                {`
            @keyframes slideLeft {
              0% {
                transform: translateX(100%);
              }
              100% {
                transform: translateX(0);
              }
            }
            @keyframes slideRight {
              0% {
                transform: translateX(-100%);
              }
              100% {
                transform: translateX(0);
              }
            }
            .slide-left {
              animation: slideLeft 0.6s ease forwards;
            }
            .slide-right {
              animation: slideRight 0.6s ease forwards;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            .fade-in {
              animation: fadeIn 0.5s ease;
            }
          `}
              </style>

              {/* Contenedor de la tarjeta */}
              <div style={styles.cardContainer}>
                {/* Nuevo carrusel con imágenes unidas */}
                <div
                  style={
                    (styles.cardContainer = {
                      
                      width: "100%",
                      height: "auto",
                      backgroundColor: "#fff",
                      maxWidth: "35rem",
                      overflow: "hidden",
                      position: "relative",
                      display: "flex", // Agregado
                      flexDirection: "column", // Agregado
                    })
                  }
                >
                  <div style={styles.carouselTrack}>
                    {itemsData.map((item) => (
                      <div key={item.id} style={styles.imageWrapper}>
                        <img
                          src={item.imageUrl}
                          alt={item.title}
                          style={
                            (styles.cardImage = {
                              width: "100%",
                              height: "auto",
                              aspectRatio: "1 / 1", // Mantiene la imagen cuadrada
                              objectFit: "cover",
                            })
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Título con fade-in */}
                <div style={styles.titleContainerBase}>
                  <span style={styles.cardIcon}>
                    {itemsData[currentIndex].icon}
                  </span>
                  <h2 style={styles.cardTitle}>
                    {itemsData[currentIndex].title}
                  </h2>
                </div>
              </div>

              {/* Indicadores */}
              <div style={styles.indicatorsContainer}>
                {itemsData.map((item, index) => {
                  const isActive = index === currentIndex;
                  return (
                    <div
                      key={item.id}
                      onClick={() => handleIndicatorClick(index)}
                      style={{
                        ...styles.indicatorBase,
                        ...(isActive ? styles.indicatorActive : {}),
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <Box
              sx={{
                maxWidth: `${pagewidth}px`,
                margin: "0 auto",
                pb: "8rem", // padding inferior
                pt: "2rem",
                justifyContent: "center", // Centra horizontalmente el contenido
              }}
            >
              {/* Tabs */}
              <Box width="100%" display="flex" justifyContent="center">
                <Box
                  sx={{
                    position: "relative",
                    width: "90%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    backgroundColor: "transparent",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <Box
                      key={tab.label}
                      onClick={() => handleTabClick(index)}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      ref={(el) => (tabRefs.current[index] = el)}
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        padding: "0.625rem",
                        cursor: "pointer",
                        fontSize: "0.875rem",
                        fontFamily: "Inter",
                        position: "relative",
                        transition: "color 0.3s ease-in-out",
                        color:
                          selectedTab === index
                            ? "white"
                            : hoveredIndex === index
                            ? colors.grey[200]
                            : colors.grey[400],
                        fontWeight: selectedTab === index ? 500 : 400,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {React.cloneElement(tab.icon, {
                        style: { fontSize: "1.25rem", marginRight: "0.25rem" },
                      })}

                      {tab.label}
                    </Box>
                  ))}

                  <Box
                    ref={underlineRef}
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      height: "3px",
                      backgroundColor: "white",
                      transition: "all 0.3s ease",
                    }}
                  />
                </Box>
              </Box>

              {/* Imagen justo debajo de las tabs, sin espacio extra */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={tabs[selectedTab].image}
                  alt={tabs[selectedTab].label}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "1rem",
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
