import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { ContactFormL } from "./Forms/ContactFormL";
import backgroundModal from "../Data/Assets/Images/backgroundModal.png";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import logo_bN from "../Data/Assets/LogoModal.png";
import { PrimaryText } from "./Texts/PrimaryText";

import logo1 from "../Data/Assets/Logos/brainUpGrup.png";
import logo2 from "../Data/Assets/Logos/adestic.png";
import logo3 from "../Data/Assets/Logos/kactus.png";

export const ModalContact = ({ open, setOpen }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const logos = [logo1, logo2, logo3];

  // Pre-cargar la imagen
  useEffect(() => {
    const img = new Image();
    img.src = backgroundModal;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          borderRadius: "1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80rem",
          maxWidth: "90vw",
          bgcolor: "white",
          boxShadow: 24,
          outline: "none",
          maxHeight: "90%",
          overflow: "hidden",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "50%",
              borderRadius: "1rem",
              zIndex: -1,
              backgroundColor: imageLoaded ? "transparent" : colors.grey[200], // Color de fondo mientras carga
              backgroundImage: imageLoaded ? `url(${backgroundModal})` : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!imageLoaded && <CircularProgress color="primary" />}
          </Box>
        )}
        <Box sx={{ p: isMobile ? 1 : 5, borderRadius: "1rem", width: "100%" }}>
          <Grid container>
            {!isMobile && (
              <Grid item xs={6} height={"10rem"} width={"100%"}>
                <Stack p="2rem 2rem 2rem 0" spacing={3}>
                  <img
                    src={logo_bN}
                    style={{
                      width: "15rem",
                      marginTop: "0.25rem",
                      marginLeft: "1.5rem",
                    }}
                    alt="Logo"
                  />
                  <Box sx={{ ml: "1rem" }}>
                    <Typography
                      mt="1rem"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "1.5rem",
                        color: colors.grey[700],
                        fontWeight: 550,
                        lineHeight: "2rem",
                        p: "0 1.5rem",
                      }}
                    >
                      Impulsa tu{" "}
                      <span style={{ color: colors.primary[500] }}>
                        agencia
                      </span>{" "}
                      creando Asistentes Virtuales con{" "}
                      <span style={{ color: colors.primary[500] }}>IA</span>{" "}
                      para tus clientes.
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[100],
                        p: "1.5rem",
                        mt: "6rem",
                        borderRadius: "1rem",
                        border: `1px solid ${colors.grey[175]}`,
                      }}
                    >
                      <PrimaryText
                        text="Ellos ya confían en nosotros:"
                        greyColor
                      />
                      <Box height="4rem">
                        <Grid container mt="1.5rem">
                          {logos.map((logo, index) => (
                            <Grid
                              item
                              xs={4}
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={logo}
                                alt={`logo-dup-${index}`}
                                style={{
                                  width: "7rem",
                                  height: "auto",
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            )}
            <Grid item xs={isMobile ? 12 : 6} height={"100%"}>
              <ContactFormL version="small" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
