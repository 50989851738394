// ScreenSizeContext.js
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useTheme, useMediaQuery } from "@mui/material";

const ScreenSizeContext = createContext({ screenSize: "sm" });

export const useScreenSize = () => useContext(ScreenSizeContext);

export const ScreenSizeProvider = ({ children }) => {
  const contactFormRef = useRef(null);
  const scrollToContact = () => {
    contactFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const pagewidth = 1200;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const screenSize = useMemo(() => {
    if (isXl) return "xl";
    if (isLg) return "lg";
    if (isMd) return "md";
    if (isSm) return "sm";
    if (isXs) return "xs";
    return "sm";
  }, [isXs, isSm, isMd, isLg, isXl]);

  useEffect(() => {
    console.log(screenSize);
  }, [screenSize]);

  return (
    <ScreenSizeContext.Provider
      value={{ screenSize, pagewidth, scrollToContact, contactFormRef }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};
