import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Stack,
  Box,
  Grid,
  InputBase,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import postData from "../../hooks/postData";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SuccessMessage from "../SuccessMessage";
import apiClientForm from "../../hooks/api-client-form";

import logo1 from "../../Data/Assets/Logos/brainUpGrup.png";
import logo2 from "../../Data/Assets/Logos/adestic.png";
import logo3 from "../../Data/Assets/Logos/kactus.png";
import { PrimaryText } from "../Texts/PrimaryText";

import backgroundImage from "../../Data/Assets/Images/backgroundModal.png";

export const ContactFormL = ({ version = "large" }) => {
  const logos = [logo1, logo2, logo3];
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth, contactFormRef } = useScreenSize();
  // Estados para cada campo de entrada
  const [email, setEmail] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [control, setControl] = useState(0);
  const { data } = postData(
    "/user_info/",
    { email: email, name: comments, company: companyName },
    control
  );
  const { screenSize } = useScreenSize();

  const [showMessage, setShowMessage] = useState({
    visible: false,
    success: null,
    message: "",
  });

  useEffect(() => {
    if (data) {
      setComments("");
      setCompanyName("");
      setEmail("");
      setLoading(false);
      // Abrir el Snackbar cuando `data` se actualiza
      setOpen(true);
      // Cerrar el Snackbar después de 3 segundos
      setTimeout(() => {
        setOpen(false);
      }, 6000);
    }
  }, [data]);

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await apiClientForm.post(
        "/chatbot/tenants/byneural_K5/lead-data/",
        {
          "Nombre / Nombre Agencia": companyName,
          lead_version: "Agencias",
          "E-mail / Teléfono": email,
          Comentario: comments,
        }
      );
      if (response && response.data) {
        setShowMessage({
          visible: true,
          success: true,
          message: "Información enviada a el equipo de byNeural.",
        });
        setLoading(false);
      } else {
        setShowMessage({
          visible: true,
          success: false,
          message: "Ha habido un error al enviar la información.",
        });
        setLoading(false);
      }
    } catch (err) {
      setShowMessage({
        visible: true,
        success: false,
        message: "Ha habido un error al enviar la información.",
      });
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <SuccessMessage open={showMessage} setShowMessage={setShowMessage} />

      <Stack sx={{ maxWidth: pagewidth }}>
        <Grid
          ref={contactFormRef}
          container
          alignItems="center"
          justifyContent="center" // Esta propiedad centra los elementos horizontalmente
          sx={{
            // border: `2px solid ${colors.primary[500]}`,

            borderRadius: "1rem",
            // background: `linear-gradient(30deg, rgba(69, 53, 220,  0.5), rgba(69, 53, 220, 0.1))`,
            padding: isMobile ? "2rem 0rem" : "5rem 0rem",
            maxWidth: "90%",
            zIndex: "1",
            margin: "0 auto", // Asegúrate de que el contenedor mismo esté centrado en su contenedor padre
          }}
        >
          <Grid item xs={12}>
            <Stack
              sx={{
                maxWidth: { xs: "100%", md: "37rem" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: version === "small" ? "600" : "750",
                  color: colors.grey[900],
                  textAlign: "center",
                  whiteSpace: "normal",
                  overflow: "visible",
                  fontSize: version === "small" ? "1.25rem" : "2rem",
                  fontFamily: "Inter",
                }}
              >
                Contacta con nosotros
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    xs: "1rem",
                    md: version === "small" ? "1rem" : "1.125rem",
                  },
                  fontFamily: "inter",
                  fontWeight: version === "small" ? "400" : "normal",
                  color:
                    version === "small" ? colors.grey[500] : colors.grey[700],
                  mt: version === "small" ? "0.25rem" : "0.5rem",
                }}
              >
                Respondemos en menos de 24h.
              </Typography>
            </Stack>
          </Grid>

          {/* Input field for Company Name */}
          <Grid item xs={12} sx={{ mt: "2rem" }}>
            <Box
              sx={{
                // background: colors.grey[250],
                border: `1px solid ${colors.grey[300]}`,
                background: "white",

                maxWidth: { xs: "100%", md: "37rem" },
                borderRadius: "0.75rem",
                padding: "0.2rem",
              }}
            >
              <InputBase
                disabled={loading}
                fullWidth
                placeholder="Nombre / Nombre Agencia"
                inputProps={{ "aria-label": "company-name" }}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{
                  color: "black",
                  "& .MuiInputBase-input": {
                    textAlign: "center", // Alinea el texto al centro
                    fontSize: screenSize === "sm" ? "1.125rem" : "1.125rem",
                    fontWeight: "normal",
                    fontFamily: "inter",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    // Estilos para el placeholder
                    color: colors.grey[500],
                    fontSize: screenSize === "sm" ? "1.125rem" : "1.125rem",
                    fontWeight: "normal",
                    fontFamily: "inter",
                  },
                }}
              />
            </Box>
          </Grid>
          {/* Input field for Email */}
          <Grid item xs={12} sx={{ mt: "1rem" }}>
            <Box
              sx={{
                maxWidth: { xs: "100%", md: "37rem" },
                // background: colors.grey[250],
                background: "white",

                border: `1px solid ${colors.grey[300]}`,
                borderRadius: "0.75rem",
                padding: "0.2rem",
              }}
            >
              <InputBase
                fullWidth
                placeholder="E-mail / Teléfono"
                inputProps={{ "aria-label": "email" }}
                value={email}
                disabled={loading}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  color: "black",

                  "& .MuiInputBase-input": {
                    textAlign: "center", // Alinea el texto al centro
                    fontSize: screenSize === "sm" ? "1.125rem" : "1.125rem",
                    fontWeight: "normal",
                    fontFamily: "inter",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    // Estilos para el placeholder
                    color: colors.grey[500],
                    fontSize: screenSize === "sm" ? "1.125rem" : "1.125rem",
                    fontWeight: "normal",
                    fontFamily: "inter",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Input field for Comments */}
          <Grid item xs={12} sx={{ mt: "1rem" }}>
            <Box
              sx={{
                // background: colors.grey[250],
                border: `1px solid ${colors.grey[300]}`,
                background: "white",

                maxWidth: { xs: "100%", md: "37rem" },
                borderRadius: "0.75rem",
                padding: "10px",
              }}
            >
              <InputBase
                disabled={loading}
                fullWidth
                placeholder="¿Algún comentario?"
                multiline
                rows={4}
                inputProps={{ "aria-label": "comments" }}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{
                  color: "black",

                  "& .MuiInputBase-input": {
                    textAlign: "center", // Alinea el texto al centro
                    fontSize: screenSize === "sm" ? "1.125rem" : "1.125rem",
                    fontWeight: "normal",
                    fontFamily: "inter",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    // Estilos para el placeholder
                    color: colors.grey[500],
                    fontSize: screenSize === "sm" ? "1.125rem" : "1.125rem",
                    fontWeight: "normal",
                    fontFamily: "inter",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Submit button */}
          <Grid item xs={12}>
            <Button
              onClick={handleSend}
              sx={{
                maxWidth: { xs: "100%", md: "37rem" },
                alignSelf: "center",
                backgroundColor:
                  version === "small" ? colors.primary[500] : colors.grey[700],
                borderRadius: "0.75rem",
                color: colors.grey[100],
                width: "100%",
                height: version === "small" ? "2.75rem" : "3.875rem", // Asegúrate de no duplicar la propiedad 'height'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: "2rem",
                "&:hover": {
                  backgroundColor: colors.grey[600],
                  color: colors.grey[100], // Cambia a azul cuando el cursor está encima
                },
              }}
            >
              {loading ? (
                <Box mt="0.5rem">
                  <CircularProgress sx={{ color: colors.grey[100] }} />
                </Box>
              ) : (
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "1rem", md: "1.125rem" },
                    fontWeight: 600,
                    fontFamily: "inter",
                  }}
                >
                  Enviar
                </Typography>
              )}
            </Button>
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              <Box>
                <Box
                  sx={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: "bottom", // La imagen comienza desde la parte inferior
                    backgroundRepeat: "no-repeat", // Evita que la imagen se repita
                    p: "1.5rem",
                    mt: "2rem",
                    borderRadius: "1rem",
                    border: `1px solid ${colors.grey[175]}`,
                  }}
                >
                  <PrimaryText text="Ellos ya confían en byNeural:" greyColor />
                  <Box height="4rem">
                    <Grid container mt="1.5rem">
                      {logos.map((logo, index) => (
                        <Grid
                          item
                          xs={4}
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={logo}
                            alt={`logo-dup-${index}`}
                            style={{
                              width: "6rem",
                              height: "auto",
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{
            borderRadius: "0.75rem",
            width: "100%",
            fontFamily: "inter",
            fontSize: "1rem",
          }}
        >
          Datos de contacto enviados. ¡Te escribiremos pronto!
        </Alert>
      </Snackbar>
    </>
  );
};
