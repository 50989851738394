import {
  Box,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import backgroundImage from "../../Data/Assets/background_form.png";
import laptopImage from "../../Data/Assets/demo_laptop.png";
import SmallButton from "../Buttons/SmallButton";
import BigButton from "../Buttons/BigButton";
import { motion, useTime, useTransform } from "framer-motion";

const FormCard = ({ setOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const time = useTime();
  const rotate = useTransform(time, [0, 3000], [0, 360], { clamp: false });
  const rotatingBg = useTransform(
    rotate,
    (r) => `conic-gradient(from ${r}deg, #4834DC, #fff, #fff, #4834DC)`
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ width: "100%", position: "relative", padding: "1px" }}>
      <motion.div
        style={{
          position: "absolute",
          inset: 0,
          borderRadius: "1rem",
          background: rotatingBg,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "15px",
          padding: "0 4rem",
          width: "100%",
          height: { xs: "auto", md: "auto" },

          fontFamily: "Inter, sans-serif",
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{ height: "100%", width: "100%" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Stack
              sx={{
                padding: { xs: "2rem 2rem", lg: "5rem 3rem" },
                height: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                fontWeight="bold"
                fontSize={isMobile ? "1.5rem" : "2rem"}
                lineHeight={1.3}
                sx={{ fontFamily: "Inter, sans-serif", textAlign: "center" }}
              >
                ¿Hablamos y empezamos?
              </Typography>

              <Typography
                fontSize={isMobile ? "0.875rem" : "1.25rem"}
                lineHeight={1.6}
                sx={{
                  fontFamily: "Inter, sans-serif",
                  marginTop: "1rem",
                  marginBottom: "0rem",
                  color: colors.grey[600],
                  textAlign: "center",
                }}
              >
                Solicita una demo gratuita. Sin compromisos y en menos de 24
                horas.
              </Typography>
              <Box sx={{ mt: "3rem" }}>
                <BigButton
                  label="Solicitar Demo Gratis"
                  variant="primary"
                  onClick={() => setOpen(true)}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FormCard;
