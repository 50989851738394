import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import logo1 from "../Data/Assets/Logos/adestic.png";
import logo2 from "../Data/Assets/Logos/brainUpGrup.png";
import logo3 from "../Data/Assets/Logos/kactus.png";
import logo4 from "../Data/Assets/Logos/funneltropia.png";
import logo5 from "../Data/Assets/Logos/konfyans.png";
import logo6 from "../Data/Assets/Logos/emfasi.png";
import logo7 from "../Data/Assets/Logos/loopalabs.png";
import logo8 from "../Data/Assets/Logos/dasMedia.png";
import logo9 from "../Data/Assets/Logos/webyseo.png";

const logos = [
  { img: logo1, url: "https://adestic.com/" },
  { img: logo2, url: "https://www.brainupgrup.com/" },
  { img: logo3, url: "https://agenciakactus.com/" },
  { img: logo4, url: "https://www.funneltropia.com/" },
  { img: logo5, url: "https://www.konfyans.eu/" },
  { img: logo6, url: "https://emfasi.com/" },
  { img: logo7, url: "https://loopalabs.com/" },
  { img: logo8, url: "https://dasmedia.es/" },
  { img: logo9, url: "https://www.webyseo.es" },
];

export const CarrouselLogos = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <style>
        {`
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(calc(-50% - 32px)); } /* Ajustamos para el espacio de 32px */
        }

        .slider {
          background: transparent; /* Fondo transparente */
          height: 128px; /* Altura fija del contenedor */
          margin: auto;
          overflow: hidden;
          position: relative;
          width: 100%;
          max-width: 100%;
          display: flex;
          align-items: center; /* Centrar verticalmente */
        }

        .slider::before,
        .slider::after {
          content: "";
          height: 100%;
          position: absolute;
          width: 200px;
          z-index: 2;
          background: linear-gradient(to right, rgba(244,244,252, 1) 0%, rgba(244,244,252, 0) 100%);
        }

        .slider::after {
          right: 0;
          top: 0;
          transform: rotateZ(180deg);
          background: linear-gradient(to right, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%);
        }

        .slider::before {
          left: 0;
          top: 0;
        }

        .slide-track {
          animation: scroll ${
            logos.length * 1.5
          }s linear infinite; /* Animación más rápida */
          display: flex;
          gap: 64px; /* Espacio fijo de 32px entre imágenes */
          align-items: center; /* Centrar verticalmente */
        }

        .slide {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        .slide img {
          width: auto;
          height: 40px; /* Altura fija de 40px */
          filter: grayscale(1);
          transition: filter 0.3s ease-in-out;
          cursor: pointer;
        }

        .slide img:hover {
          filter: grayscale(0);
        }
      `}
      </style>

      <div className="slider">
        <div className="slide-track">
          {/* Duplicamos los logos para crear el efecto de carrusel infinito */}
          {[...logos, ...logos].map((logo, index) => (
            <div className="slide" key={index}>
              <img
                src={logo.img}
                alt={`logo-${index}`}
                onClick={() => window.open(logo.url, "_blank")}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
