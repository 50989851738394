import React, { useEffect, useRef, useState } from "react";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ScreenSizeProvider } from "./hooks/ScreenSizeContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AccountPolitics } from "./scenes/AccountPolitics";
import { AssistantPolitics } from "./scenes/AssistantPolitics";
import { AvisoLegal } from "./scenes/AvisoLegal";
import TopBar from "./Components/TopBar";
import Page404 from "./scenes/Page404";
import Lenis from "@studio-freight/lenis";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ModalContact } from "./Components/ModalContact";
import { MainLanding } from "./scenes/main/MainLanding";
import { TeamPage } from "./scenes/equipo/TeamPage";
import {
  Ecommerce,
  eCommerce,
  EcommercePage,
} from "./scenes/eCommerce/EcommercePage";

gsap.registerPlugin(ScrollTrigger);

function App() {
  const [theme, colorMode] = useMode();

  const [open, setOpen] = useState(false);

  const lenisRef = useRef(null);

  useEffect(() => {
    // Inicializar Lenis
    const lenis = new Lenis({
      smooth: true,
      lerp: 0.1, // Ajusta la suavidad del scroll
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
    lenisRef.current = lenis;

    return () => lenis.destroy(); // Cleanup al desmontar
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScreenSizeProvider>
          <ModalContact open={open} setOpen={setOpen} />

          <Router>
            <Box id="smooth-wrapper">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Box id="smooth-content">
                      <TopBar page="/" setOpen={setOpen} />
                      <MainLanding setOpen={setOpen} />
                    </Box>
                  }
                />

                <Route
                  path="/equipo"
                  element={
                    <Box>
                      <TopBar page="/equipo" setOpen={setOpen} />
                      <TeamPage setOpen={setOpen} />
                    </Box>
                  }
                />
                <Route
                  path="/ecommerce"
                  element={
                    <Box>
                      <TopBar page="/ecommerce" setOpen={setOpen} />
                      <EcommercePage setOpen={setOpen} />
                    </Box>
                  }
                />

                <Route
                  path="/politicas-asistentes"
                  element={
                    <Box>
                      <TopBar page="/politicas-asistentes" />
                      <AssistantPolitics />
                    </Box>
                  }
                />
                <Route
                  path="/politicas-cuentas"
                  element={
                    <Box>
                      <TopBar page="/politicas-cuentas" />
                      <AccountPolitics />
                    </Box>
                  }
                />
                <Route
                  path="/aviso-legal"
                  element={
                    <Box>
                      <TopBar page="/aviso-legal" />
                      <AvisoLegal />
                    </Box>
                  }
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Box>
          </Router>
        </ScreenSizeProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
