import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import React from "react";
import { FeatureGridItem } from "../Cards/FeatureGridItem";

import openai from "../../Data/Assets/Images/Features/OpenAI.png";
import video from "../../Data/Assets/Images/Features/Video_phone.png";
import make from "../../Data/Assets/Images/Features/Make.png";

export const FeaturesGrid2 = ({ globalTime }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width={"100%"}>
      <Box
        sx={{
          width: "100%",
          justifyItems: "center",
          borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          width={"100%"}
          sx={{ display: "flex" }}
        >
          <FeatureGridItem
            backgroundImage={make}
            text="Integración con Make"
            forceActive={globalTime % 12 >= 6 && globalTime % 12 < 7.8}
          />
          <FeatureGridItem
            backgroundImage={openai}
            text="Modelos de OpenAI"
            border={!isMobile}
            forceActive={globalTime % 12 >= 8 && globalTime % 12 < 9.8}
          />
          <FeatureGridItem
            backgroundImage={video}
            text="Muestra imágenes y vídeos"
            forceActive={globalTime % 12 >= 10 && globalTime % 12 < 11.8}
          />
        </Stack>
      </Box>
    </Box>
  );
};
