import React, { useRef, useState, useEffect } from "react";
import { Box, IconButton, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import video from "../Data/Assets/12-02-Sin_musica.mp4";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { FaPlay, FaExpand } from "react-icons/fa";
import { PiCornersOut, PiCornersOutBold } from "react-icons/pi";

const VIDEO_DURATION = 79; // Duración del video en segundos

export const VideoComponent = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { pagewidth } = useScreenSize();

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;
  
    const handleOnPlay = () => setIsPlaying(true);
    const handleOnPause = () => setIsPlaying(false);
  
    videoElement.addEventListener("play", handleOnPlay);
    videoElement.addEventListener("pause", handleOnPause);
  
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handleOnPlay);
        videoElement.removeEventListener("pause", handleOnPause);
      }
    };
  }, []);

  useEffect(() => {
    const updateProgress = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        setProgress((currentTime / VIDEO_DURATION) * 100);
      }
    };

    let interval;
    if (isPlaying) {
      interval = setInterval(updateProgress, 100);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPlaying]);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleFullscreenVideo = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen(); // Para Safari
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen(); // Para IE/Edge
      }
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: `${pagewidth + 200}px`,
        aspectRatio: "16 / 9",
        margin: "0 auto",
        borderRadius: "1rem",
        overflow: "hidden",
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src={video} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>

      {/* Capa de blur y botón de Play en móvil */}
      {isMobile && !isPlaying && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(8px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handlePlayVideo}
        >
          <IconButton sx={{ color: "white", fontSize: "4rem" }}>
            <FaPlay />
          </IconButton>
        </Box>
      )}

      {/* Botón de pantalla completa en móvil */}
      
        <IconButton
          onClick={handleFullscreenVideo}
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 10,
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
          }}
        >
          <PiCornersOutBold size={"1.5rem"} />
      </IconButton>
      
      {/* Barra de progreso en escritorio */}
      {!isMobile && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "4px",
            backgroundColor: "#E0E0E0",
          }}
        >
          <Box
            sx={{
              width: `${progress}%`,
              height: "100%",
              backgroundColor: "#4844DC",
              transition: "width 0.1s linear",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
