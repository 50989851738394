import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import BigButton from "../Buttons/BigButton";
import { TopText } from "./TopText";
import ecommerceImage from "../../Data/Assets/Images/Ecommerce/ecommerce.png";
import Logo1 from "../../Data/Assets/Logos/Joguiba.png";
import Logo2 from "../../Data/Assets/Logos/Valkanik.png";
import Logo3 from "../../Data/Assets/Logos/Luzeco.png";
import Logo4 from "../../Data/Assets/Logos/Natural Suit.png";

const EcommerceHero = ({ variant, setOpen }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animation, setAnimation] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth, scrollToContact } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box>
      <Grid container spacing={isMobile ? 4 : 8}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: isMobile ? "35rem" : "100%",
              }}
            >
              {!isMobile && <TopText text="ecommerce" left />}
              <Typography
                lineHeight={"120%"}
                component="h1"
                sx={{
                  fontSize: { xs: "2.25rem", lg: "3rem" },
                  fontWeight: { xs: "650", md: "750" },
                  color: colors.grey[900],
                  whiteSpace: "normal",
                  overflow: "visible",
                  fontFamily: "Inter",
                  textAlign: isMobile ? "center" : "left",
                  width: { xs: "100%", md: "100%" },
                }}
              >
                Haz que los{" "}
                <span style={{ color: colors.primary[500] }}>eCommerce </span>
                de tus clientes{" "}
                <span style={{ color: colors.primary[500] }}>vendan más </span>
                gracias a la IA
              </Typography>
              <Typography
                fontSize={isMobile ? "1.25rem" : "1.25rem"}
                lineHeight={"1.35"}
                component="h2"
                sx={{
                  marginTop: { xs: "1rem", md: "1rem" },
                  color: colors.grey[500],
                  textAlign: isMobile ? "center" : "left",
                  fontFamily: "Inter",
                  fontWeight: "400",
                }}
              >
                Crea asistentes virtuales con IA que recomiendan y muestran
                productos, informan sobre el estado del pedido y proporciona
                métricas sobre ventas.
              </Typography>
              <Box
                sx={{
                  marginTop: { xs: "3rem", md: "3rem", lg: "6rem" }, // Espacio entre el texto y los botones
                  width: "100%",
                  display: "flex",
                  marginBottom: {xs: "0rem", md: "2rem"},
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex", // Usamos flexbox para alinear los botones en línea
                    flexDirection: "row",
                    gap: "1rem", // Espacio entre los botones
                    justifyContent: isMobile ? "center" : "left", // Centrar los botones en todos los tamaños de pantalla
                  }}
                >
                  {isMobile ? null : (
                    <BigButton
                      label="Contáctanos"
                      variant="secondary"
                      onClick={() => setOpen(true)}
                    />
                  )}

                  <BigButton
                    label="Solicitar Demo Gratis"
                    variant="primary"
                    onClick={() => setOpen(true)}
                  />
                </Box>
              </Box>
              {isLg && (
                <Stack
                  direction="row"
                  spacing={4}
                  sx={{
                    marginTop: { xs: "2rem", md: "3rem" },
                    marginBottom: "2rem",
                    width: "100%",
                    justifyContent: "left", // Asegura que los logos se mantengan alineados en el centro
                    alignItems: "center", // Evita desalineaciones verticales
                  }}
                >
                  {[Logo1, Logo2, Logo3, Logo4].map((logo, index) => (
                    <Box
                      key={index}
                      sx={{
                        height: "48px",
                        display: "flex",
                        alignItems: "left",
                      }}
                    >
                      <img
                        src={logo}
                        alt={`Logo ${index + 1}`}
                        style={{
                          height: "100%", // Mantiene el tamaño uniforme
                          width: "auto", // Evita distorsión
                          filter: "grayscale(100%)",
                          transition: "filter 0.3s ease-in-out",
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }} // Centra el Grid
        >
          <Box
            sx={{
              display: "flex", // Añadido para centrar dentro del Box
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={ecommerceImage}
              alt="ecommerce"
              style={{
                width: "100%",
                height: "auto", // Evita distorsiones
                maxWidth: isMobile ? "30rem" : "100%",
                objectFit: "contain", // Ajusta la imagen sin cortar
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EcommerceHero;
