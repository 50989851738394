import React from "react";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { Box, Grid, useMediaQuery } from "@mui/material";
import TitleHero from "../../Components/Texts/TitleHero";
import TeamHero from "../../Components/Texts/TeamHero";
import ProfileGrid from "../../Components/Sections/ProfileGrid";
import { PartnersCard } from "../../Components/Cards/PartnersCard";
import { ContactFormL } from "../../Components/Forms/ContactFormL";
import Footer from "../../Components/Footer";
import background from "../../Data/Assets/Images/Fondo.svg";

export const TeamPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, contactFormRef } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const determinePadding = () => {
    switch (screenSize) {
      case "xs":
        return "0rem 0.75rem";
      case "sm":
        return "0rem 1.5rem";
      case "md":
        return "0rem 2rem";
      case "lg":
        return "0rem 1rem";
      default:
        return "0rem 1rem";
    }
  };

  const maxWidthPage = "1400px";
  const maxWidthSection = "1240px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        overflowX: "hidden",
      }}
    >
      {/* Background */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: -10,
          width: "100vw",
          zIndex: -1,
        }}
      >
        <>
          <div
            style={{
              width: "100vw",
              height: "160rem",
              backgroundImage: isMobile
                ? `url(${background})`
                : `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </>
      </div>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "0rem", width: "100%" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: determinePadding(),
            width: "100%",
            overflow: "hidden",
            marginTop: "0rem",
            justifyItems: "center",
          }}
        >
          <div
            style={{
              maxWidth: maxWidthSection,
              width: "100%",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%)",
              borderImageSlice: 1,
            }}
          >
            <Box
              sx={{
                padding: {
                  xs: "6rem 0rem 4rem 0rem",
                  sm: "4rem 0rem",
                  md: "6rem 0rem 3rem 0rem",
                },
              }}
            >
              <TeamHero />
            </Box>
          </div>
        </Grid>

        {/* Cartas */}
        <Grid
          item
          xs={12}
          sx={{
            padding: determinePadding(),
            width: "100%",
            overflow: "hidden",
            marginTop: "0rem",
            justifyItems: "center",
          }}
        >
          <div
            style={{
              maxWidth: maxWidthSection,
              width: "100%",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%)",
              borderImageSlice: 1,
              padding: "1rem 4rem 8rem 4rem",
            }}
          >
            <ProfileGrid />
          </div>
        </Grid>
        {/* Sección Partners */}
        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            mt: { xs: "4rem", md: "4rem" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              maxWidth: maxWidthSection,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PartnersCard />
          </Box>
        </Grid>

        <Grid
          item
          align={"center"}
          xs={12}
          sx={{
            p: determinePadding(),
            width: "100%",
            marginTop: { xs: "4rem", md: "8rem" },
            pb: "7rem",
            pt: "2rem",
            backgroundColor: "#F5F5F9",
          }}
        >
          <ContactFormL />
        </Grid>

        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};
