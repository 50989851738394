import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import React from "react";
import { FeatureGridItem } from "../Cards/FeatureGridItem";

import products from "../../Data/Assets/Images/Features/Products_phone.png";
import multiidioma from "../../Data/Assets/Images/Features/Multiidioma.png";
import hubspot from "../../Data/Assets/Images/Features/Hubspot.png";

export const FeaturesGrid = ({ globalTime }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width={"100%"}>
      <Box
        sx={{
          width: "100%",
          justifyItems: "center",
          borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          width={"100%"}
          sx={{ display: "flex" }}
        >
          <FeatureGridItem
            backgroundImage={products}
            text="Recomienda productos"
            forceActive={globalTime % 12 < 1.8}
          />
          <FeatureGridItem
            backgroundImage={hubspot}
            text="Formularios con Hubspot"
            border={!isMobile}
            forceActive={globalTime % 12 >= 2 && globalTime % 12 < 3.8}
          />
          <FeatureGridItem
            backgroundImage={multiidioma}
            text="Responde en múltiples idiomas"
            forceActive={globalTime % 12 >= 4 && globalTime % 12 < 5.8}
          />
        </Stack>
      </Box>
    </Box>
  );
};
