import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect } from "react";

export const FeatureGridItem = ({ text, backgroundImage, border = false, forceActive }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Estado de hover y animación
  const [isHovered, setIsHovered] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);

  useEffect(() => {
    if (forceActive) {
      setAnimationActive(true);
      setTimeout(() => setIsHovered(true), 100);
    } else {
      setIsHovered(false);
      setTimeout(() => setAnimationActive(false), 500);
    }
  }, [forceActive]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "3rem",
        borderLeft: !isMobile && border ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
        borderRight: border ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
        position: "relative",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Contenedor del fondo cuadrado */}
      <Box
        sx={{
          width: "100%",
          aspectRatio: "1/1",
          position: "relative",
        }}
      >
        {/* Capa del fondo con opacidad y efecto blanco y negro */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: isMobile || isHovered ? "grayscale(0%)" : "grayscale(100%)",
            opacity: isMobile || isHovered ? 1 : 0.3,
            transition: "filter 0.8s ease-in-out, opacity 0.8s ease-in-out",
          }}
        />

        {/* Capa del degradado blanco en la parte inferior */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "50%",
            background:
              "linear-gradient(to top, white 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%)",
            zIndex: 1,
            opacity: isMobile || animationActive || isHovered ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        />

        {/* Capa de texto sobre el fondo */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "0rem",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "1.5rem",
              fontWeight: "600",
              lineHeight: "2rem",
              color: "black",
              opacity: isMobile || animationActive || isHovered ? 1 : 0,
              transform: isMobile || animationActive || isHovered ? "translateY(0)" : "translateY(10px)",
              transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
