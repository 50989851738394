import React, { useState } from "react";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FaLinkedin, FaYoutube } from "react-icons/fa6";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

import { motion, useTime, useTransform } from "framer-motion";

import israelImg from "../../Data/Assets/Images/Equipo/israel.png";
import tomasImg from "../../Data/Assets/Images/Equipo/tomash.png";
import mateoImg from "../../Data/Assets/Images/Equipo/mateo.png";
import nestorImg from "../../Data/Assets/Images/Equipo/nestor.png";
import marcelinoImg from "../../Data/Assets/Images/Equipo/marcelino.png";

import backgroundImage from "../../Data/Assets/Images/Equipo/background.png";
import logoByNeural from "../../Data/Assets/Images/Equipo/PNG.png";

// Componente individual para cada carta
const ProfileCard = ({ name, description, linkedinUrl, isWhite, image }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hovered, setHovered] = useState(false);

  const time = useTime();
  const rotate = useTransform(time, [0, 3000], [0, 360], { clamp: false });
  const rotatingBg = useTransform(
    rotate,
    (r) => `conic-gradient(from ${r}deg, #4834DC, #fff, #fff, #4834DC)`
  );

  return (
    <motion.div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      animate={{
        backgroundColor: hovered ? "rgba(255, 255, 255, 0.8)" : "transparent",
        boxShadow:
          !isWhite && hovered
            ? "0px 0px 8px 4px rgba(69,53,220,0.2)"
            : "0px 0px 0px transparent",
      }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      style={{
        width: "100%",
        borderRadius: "1rem",
      }}
    >
      <Stack direction={"column"} width={"100%"} alignItems="center">
        <Box
          sx={{
            width: "100%",
            position: "relative",
            padding: "1px",
            zIndex: 1,
            borderRadius: "1rem",
          }}
        >
          {!isWhite && (
            <motion.div
              style={{
                position: "absolute",
                inset: 0,
                borderRadius: "1rem",
                background: rotatingBg,
                zIndex: 0,
              }}
              animate={{ opacity: hovered ? 1 : 0 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
            />
          )}

          <motion.div
            style={{
              position: "relative",
              width: "100%",
              aspectRatio: "1/1",
              backgroundColor: isWhite ? "transparent" : "grey",
              borderRadius: "15px",
              backgroundImage: image ? `url(${image})` : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflow: "hidden",
            }}
          />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          sx={{ mt: "1rem", px: "1rem" }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "700",
              fontSize: "1.25rem",
            }}
          >
            {name}
          </Typography>
          {!isWhite && linkedinUrl && (
            <IconButton
              sx={{
                color: colors.grey[500],
                transition: "color 0.3s ease-in-out",
                padding: "0rem",
                "&:hover": {
                  color: colors.primary[500],
                },
              }}
              onClick={() => window.open(linkedinUrl, "_blank")}
            >
              <FaLinkedin size={24} />
            </IconButton>
          )}
        </Stack>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "1rem",
            color: "grey",
            textAlign: "left",
            width: "100%",
            px: "1rem",
          }}
        >
          {description}
        </Typography>
      </Stack>
    </motion.div>
  );
};

const ByNeuralCard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      style={{
        width: "100%",
        background: `url(${backgroundImage}) no-repeat center/cover`,
        borderRadius: "1rem",

        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        textAlign: "left",
        padding: "3rem 0rem 2rem 0rem",
      }}
    >
      <Stack width={"100%"} sx={{ padding: "0rem 1.5rem" }}>
        <Box display="flex" mb={2}>
          <img
            src={logoByNeural}
            alt="byNeural Logo"
            style={{ width: "100%", maxWidth: "160px" }}
          />
        </Box>
        <Typography
          sx={{
            color: "#343434",
            fontFamily: "Inter",
            fontSize: "1.25rem",
            fontWeight: "500",
            lineHeight: "1.5rem",
          }}
        >
          Impulsa tu{" "}
          <span style={{ color: "#4834DC", fontWeight: "bold" }}>agencia</span>{" "}
          creando Asistentes Virtuales con{" "}
          <span style={{ color: "#4834DC", fontWeight: "bold" }}>IA</span> para
          tus clientes.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={0}
        justifyContent="flex-start"
        mt={"4rem"}
        padding={"0 1.2rem"}
      >
        <IconButton
          sx={{
            color: colors.grey[500],
          }}
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/byneural-ai",
              "_blank"
            )
          }
        >
          <FaLinkedin size={24} />
        </IconButton>

        <IconButton
          sx={{
            color: colors.grey[500],
          }}
          onClick={
            () => window.open("https://www.youtube.com/@byNeural-AI", "_blank") // Reemplaza con el enlace real de YouTube
          }
        >
          <FaYoutube size={24} />
        </IconButton>
      </Stack>
    </Box>
  );
};

// Componente principal que contiene todas las cartas
const ProfileGrid = () => {
  return (
    <Stack spacing={4}>
      {/* Primera fila con 4 celdas */}
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        height={"22rem"}
      >
        <ProfileCard
          name="Israel"
          description="Co-CEO & Co-Founder"
          linkedinUrl="https://www.linkedin.com/in/israel-llagostera/"
          image={israelImg}
        />
        <ProfileCard
          name="Tomás"
          description="Co-CEO & Co-Founder"
          linkedinUrl="https://www.linkedin.com/in/tomas-sesma-urbano/"
          image={tomasImg}
        />
        <ProfileCard
          name="Mateo"
          description="CTO & Co-Founder"
          linkedinUrl="https://www.linkedin.com/in/mateo-sesma-urbano-8b950518a/"
          image={mateoImg}
        />
        <ByNeuralCard />
      </Stack>

      {/* Segunda fila con 2 celdas + 2 blancas (isWhite) */}
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        height={"22rem"}
      >
        <ProfileCard
          name="Marcelino"
          description="Consultor senior"
          linkedinUrl="https://www.linkedin.com/in/marcelino-sesma/"
          image={marcelinoImg}
        />
        <ProfileCard
          name="Néstor"
          description="Comunicación y ventas"
          linkedinUrl="https://www.linkedin.com/in/n%C3%A9stor-garc%C3%ADa-matilla-36121744/"
          image={nestorImg}
        />

        <ProfileCard isWhite />
        <ProfileCard isWhite />
      </Stack>
    </Stack>
  );
};

export default ProfileGrid;
