import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import BigButton from "../Buttons/BigButton";
import { TopText } from "./TopText";

const TeamHero = ({ variant, setOpen }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animation, setAnimation] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth, scrollToContact } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "center",
        p: "0 4rem",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
            }}
          >
            <TopText text="El equipo detrás de byneural" left />
            <Typography
              lineHeight={"120%"}
              component="h1"
              sx={{
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: { xs: "650", md: "750" },
                color: colors.grey[900],
                whiteSpace: "normal",
                overflow: "visible",
                fontFamily: "Inter",
                textAlign: "left",
              }}
            >
              Mucho más que un equipo. <br />
              Somos{" "}
              <span style={{ color: colors.primary[500] }}>
                la herramienta{" "}
              </span>
              que impulsa{" "}
              <span style={{ color: colors.primary[500] }}>tu agencia</span>.
            </Typography>
            <Typography
              fontSize={"1.25rem"}
              lineHeight={"1.35"}
              component="h2"
              sx={{
                marginTop: { xs: "1rem", md: "1.5rem" },
                color: colors.grey[500],
                textAlign: "left",
                fontFamily: "Inter",
                fontWeight: "400",
              }}
            >
              Jóvenes con talento, guiados por la experiencia y movidos por la
              ambición de revolucionar el sector de las agencias de marketing
              digital.
            </Typography>
          </Box>
        </Grid>
        {/* } */}
      </Grid>
    </Box>
  );
};

export default TeamHero;
