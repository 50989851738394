import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";

import conv from "../../Data/Assets/Images/Acordion/Conversación.png";
import stats from "../../Data/Assets/Images/Acordion/Estadísticas.png";
import recommend from "../../Data/Assets/Images/Acordion/Recomendaciones.png";

import assistantDemo from "../../Data/Assets/pop_up_accordion.mp4";

import { PiUsers, PiHeadset } from "react-icons/pi";
import { AiOutlineShopping } from "react-icons/ai";
import { MdTrendingUp } from "react-icons/md";

const CustomAccordion = styled("div")(() => ({
  marginTop: "0rem",
  marginBottom: "1rem",
  backgroundColor: "transparent",
  boxShadow: "none",
  border: "none",
  borderRadius: "1rem",
  "&::before": {
    display: "none",
  },
}));

const CustomAccordionSummary = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  padding: "1.5rem 1.5rem 1rem 1.5rem",
  backgroundColor: "transparent",
}));

export const AccordionComponent = () => {
  // Estado inicial con el primer panel seleccionado y el video activo
  const [expanded, setExpanded] = React.useState("panel0");
  const [selectedMedia, setSelectedMedia] = React.useState(assistantDemo);
  const [isVideo, setIsVideo] = React.useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();

  const isExpanded = (panel) => expanded === panel;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleHover = (panel, media, video = false) => {
    setExpanded(panel);
    setSelectedMedia(media);
    setIsVideo(video);
  };

  const panels = [
    {
      key: "panel0",
      icon: <PiHeadset style={{ fontSize: "1.5rem" }} />,
      title: "Ayuda a tus clientes",
      content:
        "Integra en las webs de tus clientes asistentes IA y convierte simples consultas en decisiones de compra, mejorando la experiencia del usuario y los resultados de tu agencia.",
      contentMobile:
        "Convierte simples consultas en decisiones de compra, mejorando la experiencia del usuario y los resultados de tu agencia.",
      videoOrImage: assistantDemo,
      isVideo: true,
    },
    {
      key: "panel1",
      icon: <PiUsers style={{ fontSize: "1.5rem" }} />,
      title: "Descubre información de los clientes",
      content:
        "Convierte cada interacción en insights únicos que otras plataformas no detectan. byNeural analiza conversaciones, identifica necesidades ocultas y te permite personalizar estrategias para maximizar el impacto de tus campañas.",
      contentMobile:
        "Analiza conversaciones, identifica necesidades ocultas ypersonaliza estrategias para maximizar el impacto de tus campañas.",
      videoOrImage: conv,
    },
    {
      key: "panel3",
      icon: <AiOutlineShopping style={{ fontSize: "1.5rem" }} />,
      title: "Aumenta la conversión",
      content:
        "byNeural no solo conversa, actúa: recomienda productos, muestra vídeos, envía formularios y más, usando IA para entender cada cliente y optimizar su experiencia en tiempo real.",
      contentMobile:
        "Recomienda productos, muestra vídeos, envía formularios y más, usando IA para entender cada cliente.",
      videoOrImage: stats,
    },
    {
      key: "panel2",
      icon: <MdTrendingUp style={{ fontSize: "1.5rem" }} />,
      title: "Recomendaciones de mejora",
      content:
        "byNeural va más allá del análisis: detecta áreas de mejora, anticipa necesidades y sugiere cambios estratégicos basados en conversaciones reales. Te ofrece recomendaciones únicas para optimizar resultados como ninguna otra plataforma.",
      contentMobile:
        "Detecta áreas de mejora, anticipa necesidades y sugiere cambios estratégicos basados en conversaciones reales.",
      videoOrImage: recommend,
    },
  ];

  return (
    <Box
      sx={{
        margin: "0 auto",
        mt: "2rem",
        width: "100%",
        maxWidth: isMobile ? "400px" : "100%",
        height: "auto",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ minHeight: "38rem" }}>
        <Grid item xs={12} md={6} pr={isMobile ? "0rem" : "1rem"}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ marginLeft: "0rem" }}>
                {panels.map((panel) => {
                  const expandedPanel = isMobile ? true : isExpanded(panel.key);

                  return (
                    <CustomAccordion
                      key={panel.key}
                      style={{
                        backgroundColor: expandedPanel
                          ? colors.grey[100]
                          : "transparent",
                        border: expandedPanel
                          ? `1px solid ${colors.grey[300]}`
                          : "none",
                        borderRadius: "1rem",
                        transition: "all 0.4s ease",
                        marginBottom: isMobile ? "2rem" : "1rem",
                      }}
                    >
                      <CustomAccordionSummary
                        onMouseEnter={() =>
                          handleHover(
                            panel.key,
                            panel.videoOrImage,
                            panel.isVideo
                          )
                        }
                        style={{
                          minHeight: "3.5rem",
                          display: "flex",
                          alignItems: "center",
                          transition: "background-color 0.4s ease",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Stack
                          direction={isMobile ? "column" : "row"}
                          spacing={2}
                          sx={{ width: "100%" }}
                        >
                          {!isMobile && panel.icon}
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "1.125rem",
                              color: expandedPanel
                                ? colors.grey[900]
                                : colors.grey[500],
                              transition: "color 0.4s ease",
                            }}
                          >
                            {panel.title}
                          </Typography>
                        </Stack>
                      </CustomAccordionSummary>

                      <Collapse in={expandedPanel} timeout={500} unmountOnExit>
                        <Box sx={{}}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: isMobile ? "0.875rem" : "1rem",
                              color: colors.grey[500],
                              pl: isMobile ? "1.5rem" : "4.125rem",
                              pr: "1.5rem",
                              pb: "1rem",
                              mt: isMobile ? "0rem" : "0rem",
                            }}
                          >
                            {isMobile ? panel.contentMobile : panel.content}
                          </Typography>

                          {/* Aquí agregamos la imagen o video debajo del texto solo en móvil */}
                          <Box sx={{ marginTop: "1rem" }}>
                            {isMobile ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {panel.isVideo ? (
                                  <video
                                    src={panel.videoOrImage}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    style={{
                                      width: "100%",
                                      borderRadius: "1rem",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={panel.videoOrImage}
                                    alt="dynamic content"
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      borderRadius: "1rem",
                                    }}
                                  />
                                )}
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      </Collapse>
                    </CustomAccordion>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Mostrar imagen/vídeo a la derecha solo en pantallas grandes */}
        {!isMobile && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: "1.5rem",
                width: "35rem",
                height: "35rem",
                backgroundColor: colors.grey[100],
                ml: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isVideo ? (
                <video
                  src={selectedMedia}
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                />
              ) : (
                <img
                  src={selectedMedia}
                  style={{
                    width: "100%",
                    height: "35rem",
                    borderRadius: "1rem",
                  }}
                  alt="dynamic content"
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
