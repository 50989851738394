import React, { useState, useEffect } from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import Footer from "../../Components/Footer";
import { AccordionComponent } from "../../Components/Sections/AccordionComponent";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

import background from "../../Data/Assets/Images/Fondo.svg";
import { VideoComponent } from "../../Components/VideoComponent";
import { CarrouselLogos } from "../../Components/CarrouselLogos";
import { CustomTitle, TitleBox2 } from "../../Components/Texts/CustomTitle";
import TitleHero from "../../Components/Texts/TitleHero";
import { BenefitCard } from "../../Components/Cards/BenefitCard";
import DemoCard from "../../Components/Cards/DemoCard";
import { PlatformSection } from "../../Components/Sections/PlatformSection";
import { PartnersCard } from "../../Components/Cards/PartnersCard";
import { ContactFormL } from "../../Components/Forms/ContactFormL";
import { FeaturesGrid } from "../../Components/Sections/FeaturesGrid";
import { FeaturesGrid2 } from "../../Components/Sections/FeaturesGrid2";
import { Form } from "formik";
import FormCard from "../../Components/Cards/FormCard";

export const MainLanding = ({ setOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, contactFormRef } = useScreenSize();

  const [globalTime, setGlobalTime] = useState(0); // Empieza como null

  useEffect(() => {
    const interval = setInterval(() => {
      setGlobalTime((prevTime) => (prevTime >= 11 ? 0 : prevTime + 1)); // Bucle infinito
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const determinePadding = () => {
    switch (screenSize) {
      case "xs":
        return "0rem 0.75rem";
      case "sm":
        return "0rem 1.5rem";
      case "md":
        return "0rem 2rem";
      case "lg":
        return "0rem 1rem";
      default:
        return "0rem 1rem";
    }
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = "scale(1.1)";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = "scale(1)";
  };

  const { pagewidth, scrollToContact } = useScreenSize();

  const maxWidthPage = "1400px";
  const maxWidthSection = "1240px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        overflowX: "hidden",
      }}
    >
      {/* Background */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: -10,
          width: "100vw",
          zIndex: -1,
        }}
      >
        <>
          <div
            style={{
              width: "100vw",
              height: "160rem",
              backgroundImage: isMobile
                ? `url(${background})`
                : `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </>
      </div>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "0rem", width: "100%" }}
      >
        {/* Hero */}
        <Grid
          item
          xs={12}
          sx={{
            padding: determinePadding(),
            width: "100%",
            overflow: "hidden",
            marginTop: "0rem",
            justifyItems: "center",
          }}
        >
          <div
            style={{
              margin: "0 auto",
              maxWidth: maxWidthPage,
              width: "100%",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%)",
              borderImageSlice: 1,
            }}
          >
            <Box
              sx={{
                padding: {
                  xs: "3rem 0rem 4rem 0rem",
                  sm: "4rem 0rem",
                  md: "10rem 0rem 6rem 0rem",
                },
              }}
            >
              <TitleHero setOpen={setOpen} />
            </Box>
          </div>
        </Grid>

        {/* Carrousel con Logos */}
        {!isMobile && (
          <Grid
            item
            alignItems={"center"}
            xs={12}
            sx={{ width: "100%", justifyItems: "center" }}
          >
            <div
              style={{
                // margin:"0 auto",
                width: "100%",
                borderTop: isMobile ? "none" : "1px solid",
                borderBottom: isMobile ? "none" : "1px solid",
                borderImageSource:
                  "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
                borderImageSlice: 1,
                justifyItems: "center",
              }}
            >
              <div
                style={{
                  margin: "0 auto",
                  maxWidth: maxWidthPage,
                  width: "100%",
                  borderLeft: "1px solid",
                  borderRight: "1px solid",
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <CarrouselLogos />
              </div>
            </div>
          </Grid>
        )}

        {/* Video */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            p: determinePadding(),
            width: "100%",
            justifyItems: "center",
          }}
        >
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              height: isMobile ? "0rem" : "8rem",
              maxWidth: maxWidthPage,
              width: "100%",
              justifyItems: "center",
              borderLeft: "1px solid",
              borderRight: "1px solid",
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderImageSlice: 1,
            }}
          ></div>
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              borderTop: isMobile ? "none" : "1px solid",
              borderBottom: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
              borderImageSlice: 1,
              justifyItems: "center",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                maxWidth: maxWidthPage,
                width: "100%",
                justifyItems: "center",
                borderLeft: isMobile ? "none" : "1px solid",
                borderRight: isMobile ? "none" : "1px solid",
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderImageSlice: 1,
              }}
            >
              <VideoComponent />
            </div>
          </div>
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              height: isMobile ? "4rem" : "10rem",
              maxWidth: maxWidthPage,
              width: "100%",
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 90%)",
              borderImageSlice: 1,
            }}
          ></div>
        </Grid>

        {isMobile && (
          <Grid
            item
            alignItems={"center"}
            xs={12}
            sx={{
              width: "100%",
              justifyItems: "center",
              mb: isMobile ? "4rem" : 0,
            }}
          >
            <div
              style={{
                width: "100%",
                borderTop: isMobile ? "none" : "1px solid",
                borderBottom: isMobile ? "none" : "1px solid",
                borderImageSource:
                  "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
                borderImageSlice: 1,
                justifyItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: maxWidthPage,
                  width: "100%",
                  borderLeft: "1px solid",
                  borderRight: "1px solid",
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <CarrouselLogos />
              </div>
            </div>
          </Grid>
        )}

        {/* Accordion */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            p: determinePadding(),
            pb: {
              xs: "2rem",
              md: "4rem",
              width: "100%",
              justifyItems: "center",
            },
          }}
          backgroundColor="#F5F5F9"
        >
          <Stack sx={{ maxWidth: maxWidthSection, margin: "0 auto" }}>
            <CustomTitle
              toptext={"el futuro de la IA conversacional"}
              title={
                <>
                  {" "}
                  Una{" "}
                  <span style={{ color: colors.primary[500] }}>
                    herramienta{" "}
                  </span>{" "}
                  que genera{" "}
                  <span style={{ color: colors.primary[500] }}>
                    {" "}
                    valor real
                  </span>{" "}
                </>
              }
              subtitle="Crea asistentes IA inteligentes que no solo conversan, sino que entienden, analizan y convierten interacciones en resultados reales para tu agencia y tus clientes."
            />
            <AccordionComponent />
          </Stack>
        </Grid>

        {/* Beneficios */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            p: determinePadding(),
            pb: {
              width: "100%",
              justifyItems: "center",
            },
          }}
        >
          <div
            style={{
              margin: "0 auto",
              maxWidth: maxWidthSection,
              width: "100%",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%)",
              borderImageSlice: 1,
              padding: isMobile ? "0rem 0rem 2rem 0rem" : "0rem 0rem 2rem 0rem",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CustomTitle
                toptext={"beneficios para tu agencia"}
                title={
                  <>
                    {" "}
                    ¿Cómo tu{" "}
                    <span style={{ color: colors.primary[500] }}>
                      agencia{" "}
                    </span>{" "}
                    se puede{" "}
                    <span style={{ color: colors.primary[500] }}>
                      beneficiar
                    </span>
                    ?
                  </>
                }
                subtitle="Aumenta tus ingresos, fideliza a tus clientes y optimiza sus negocios con la potencia de la IA."
              />
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={4}
                padding={isMobile ? "0rem 0rem" : "0rem 2rem"}
                width={isMobile ? "90%" : "100%"}
                justifyItems={"center"}
                margin={isMobile ? "2rem auto" : "4rem auto"}
                sx={{ maxWidth: isMobile ? "400px" : "100%" }}
              >
                <BenefitCard
                  title="Genera ingresos recurrentes"
                  description={
                    <>
                      <strong>Cobra mes a mes</strong> por asistentes IA que
                      venden y automatizan tareas para tus clientes.
                    </>
                  }
                />
                <BenefitCard
                  title={
                    <>
                      Oportunidades
                      <br />
                      de mejora
                    </>
                  }
                  description={
                    <>
                      <strong>Analiza</strong> cada interacción, detecta {""}
                      <strong>tendencias</strong> y mejora la{" "}
                      <strong>estrategia</strong> de venta de tus clientes.
                    </>
                  }
                />
                <BenefitCard
                  title={
                    <>
                      Fideliza a<br />
                      tus clientes
                    </>
                  }
                  description={
                    <>
                      Ofrece valor constante con asistentes IA que optimizan sus
                      ventas y automatizan soporte.{" "}
                      <strong>Más resultados = más confianza</strong>.
                    </>
                  }
                />
                <BenefitCard
                  title={
                    <>
                      Aumenta el
                      <br />
                      valor de tu oferta
                    </>
                  }
                  description={
                    <>
                      <strong>Diferénciate de la competencia</strong> ofreciendo
                      asistentes IA. <strong>Sé de los primeros</strong> en
                      hacerlo y lidera el mercado.
                    </>
                  }
                />
              </Stack>
            </Stack>
          </div>
        </Grid>

        {/* Sección Demo */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            width: "100%",
            justifyItems: "center",
            p: determinePadding(),
          }}
        >
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              borderTop: isMobile ? "none" : "1px solid",
              borderBottom: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
              borderImageSlice: 1,
              justifyItems: "center",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                maxWidth: isMobile ? "600px" : maxWidthSection,
                width: "100%",
                justifyItems: "center",
                borderLeft: isMobile ? "none" : "1px solid",
                borderRight: isMobile ? "none" : "1px solid",
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderImageSlice: 1,
              }}
            >
              <Box sx={{ maxWidth: maxWidthSection }}>
                <DemoCard />
              </Box>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: isMobile ? "4rem" : "10rem",
              maxWidth: maxWidthSection,
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 90%)",
              borderImageSlice: 1,
            }}
          ></div>
        </Grid>

        {/* Sección Plataforma */}
        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            display: "flex",
            justifyContent: "center",
            position: "relative", // Contenedor relativo para el fondo
            backgroundColor: "#201c1c",
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative", // Contenedor que agrupa fondo y contenido
              overflow: "hidden", // Clipa el fondo que se salga de la sección
            }}
          >
            {/* Fondo "Tron" (rejilla) */}
            <div
              className="grid-container"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                boxShadow:
                  "inset 0 0 50px 20px #201c1c, inset 0 0 80px 40px #201c1c",
              }}
            >
              <div className="plane">
                <div className="grid"></div>
                <div className="glow"></div>
              </div>
              <div className="plane">
                <div className="grid"></div>
                <div className="glow"></div>
              </div>
            </div>

            {/* Contenido de la Plataforma en primer plano */}
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
              }}
            >
              <PlatformSection />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack width={"100%"} sx={{ alignItems: "center" }}>
            <div
              style={{
                width: "100%",
                maxWidth: maxWidthSection,
                borderLeft: isMobile ? "none" : "1px solid",
                borderRight: isMobile ? "none" : "1px solid",
                borderImageSource:
                  "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
                borderImageSlice: 1,
              }}
            >
              <CustomTitle
                toptext={"la única plataforma que tiene tanto"}
                title={
                  <>
                    {" "}
                    Más que una plataforma, una{" "}
                    <span style={{ color: colors.primary[500] }}>
                      solución integral
                    </span>
                  </>
                }
                subtitle="Con tecnología avanzada y funcionalidades únicas, byNeural lleva tu agencia al siguiente nivel."
              />
            </div>
            <Stack
              direction={"row"}
              width={"100%"}
              maxWidth={isMobile ? "600px" : maxWidthSection}
              height={isMobile ? "2rem" : "6rem"}
            >
              <Box
                sx={{
                  width: "100%",
                  borderLeft: isMobile ? "none" : "1px solid",

                  borderImageSource:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%)",
                  borderImageSlice: 1,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  borderLeft: isMobile ? "none" : "1px solid",
                  borderRight: isMobile ? "none" : "1px solid",
                  borderImageSource:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%,  rgba(0, 0, 0, 0) 100%)",
                  borderImageSlice: 1,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  borderRight: isMobile ? "none" : "1px solid",
                  borderImageSource:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%)",
                  borderImageSlice: 1,
                }}
              />
            </Stack>

            {/* FeaturesGrid con borde superior */}
            <Box
              sx={{
                width: "100%",
                borderTop: isMobile ? "none" : "1px solid",
                borderImageSource:
                  "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
                borderImageSlice: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: isMobile ? "500px" : maxWidthSection,
                }}
              >
                <FeaturesGrid globalTime={globalTime} />
              </Box>
            </Box>
            {/* FeaturesGrid2 con bordes arriba y abajo */}
            <Box
              sx={{
                width: "100%",
                borderTop: isMobile ? "none" : "1px solid",
                borderBottom: isMobile ? "none" : "1px solid",
                borderImageSource:
                  "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
                borderImageSlice: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: isMobile ? "500px" : maxWidthSection,
                }}
              >
                <FeaturesGrid2 globalTime={globalTime} />
              </Box>
            </Box>

            <Stack
              direction={"row"}
              width={"100%"}
              maxWidth={maxWidthSection}
              height={isMobile ? "2rem" : "6rem"}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: maxWidthSection,
                  borderLeft: isMobile ? "none" : "1px solid",
                  borderRight: isMobile ? "none" : "1px solid",
                  borderImageSource:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%,  rgba(0, 0, 0, 0) 100%)",
                  borderImageSlice: 1,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  maxWidth: maxWidthSection,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  maxWidth: maxWidthSection,
                  borderLeft: isMobile ? "none" : "1px solid",
                  borderRight: isMobile ? "none" : "1px solid",
                  borderImageSource:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%,  rgba(0, 0, 0, 0) 100%)",
                  borderImageSlice: 1,
                }}
              />
            </Stack>
          </Stack>
        </Grid>

        {/* Sección Partners */}
        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            mt: { xs: "4rem", md: "4rem" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              maxWidth: maxWidthSection,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PartnersCard setOpen={setOpen} />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            mt: { xs: "4rem", md: "6rem" },
            mb: { xs: "4rem", md: "6rem" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              maxWidth: maxWidthSection,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormCard setOpen={setOpen} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};
