import React, { useState, useEffect } from "react";
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { green } from "@mui/material/colors";
import Fade from "@mui/material/Fade";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

const SuccessMessage = ({ open, setShowMessage, ...children }) => {
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (open && open.visible) {
      setVisible(true);
      const timer = setTimeout(
        () => {
          setVisible(false);
        },
        open.success ? 3000 : 6000
      );
      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (!visible) {
      setShowMessage({
        visible: false,
        success: open.success,
        message: open.message,
      });
    }
  }, [visible]);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Snackbar
      open={visible}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Fade}
      onClose={handleClose}
    >
      <SnackbarContent
        style={{
          backgroundColor: open
            ? open.success
              ? "#CFEBD2"
              : "#FFD8D8"
            : "#CFEBD2",
          maxWidth: "100%",
          borderRadius: "0.75rem",
        }}
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            {open.success ? (
              <CheckCircleIcon style={{ marginRight: "8px", color: "black" }} />
            ) : (
              <IoAlertCircleOutline
                style={{
                  fontSize: "1.6rem",
                  marginRight: "8px",
                  color: "black",
                }}
              />
            )}
            <Typography
              sx={{
                whiteSpace: "pre-line",
                fontSize: "0.875rem",
                fontFamily: "Inter",
                fontWeight: 400,
                color: colors.grey[900],
                textAlign: "left",
              }}
            >
              {open.message}
            </Typography>
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="black"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default SuccessMessage;
