import React from "react";
import { Box, Typography, Stack, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import Footer from "../Components/Footer";

export const AccountPolitics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth } = useScreenSize();

  const mainTitleStyle = {
    mt: "3rem",
    lineHeight: "3rem",
    fontWeight: "900",
    fontSize: "3rem",
    fontFamily: "Inter",
    color: colors.grey[900],
  };

  const subtitleStyle = {
    mt: "2rem",
    fontSize: "1.25rem",
    fontFamily: "Inter",
    color: colors.primary[500],
  };

  const textStyle = {
    mt: "1rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
  };

  const listItemTextStyle = {
    mt: "1rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: 0,
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };
  const listItemTextStyleNext = {
    mt: "0rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: 0,
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };

  const determinePadding = () => {
    switch (screenSize) {
      case "md":
        return "0rem 2rem 0rem 2rem";
      case "lg":
        return "0rem 2rem 0rem 2rem";
      default:
        return "0rem 1rem 0rem 1rem";
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          height: "100%",
          maxWidth: `${pagewidth}px`,
          margin: "auto",
          padding: determinePadding(),
        }}
      >
        <Stack>
          <Typography sx={mainTitleStyle}>
            Política de Privacidad para la Creación de una Cuenta Nueva
          </Typography>
          <Typography sx={subtitleStyle}>
            Introducción a la Política de Privacidad
          </Typography>
          <Typography sx={textStyle}>
            En byNeural, desarrollamos soluciones avanzadas de Asistentes
            Virtuales utilizando inteligencia artificial, diseñadas para
            optimizar los procesos de ventas, atención al cliente y engagement
            de usuarios. Entendemos la importancia de la privacidad personal y
            estamos comprometidos a proteger la información de nuestros usuarios
            respetando sus derechos de privacidad. <br></br> Esta Política de
            Privacidad explica cómo byNeural recopila, maneja y utiliza tus
            datos personales, y clarifica los derechos que tienes respecto a tu
            información personal. Somos conscientes de que la protección de
            datos es un compromiso a largo plazo, por lo que revisamos y
            actualizamos nuestra Política de Privacidad regularmente para
            reflejar las mejores prácticas y nuevos requisitos reglamentarios.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Contacto para Asuntos de Protección de Datos
          </Typography>
          <Typography sx={textStyle}>
            Si tienes preguntas sobre cómo manejamos la información personal, o
            si deseas ejercer tus derechos relacionados con tus datos
            personales, por favor contacta a nuestro equipo de privacidad.
            Estamos aquí para ayudarte y garantizar que tus datos se procesen
            con el máximo nivel de cuidado y seguridad.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Datos de Contacto del Equipo de Privacidad
          </Typography>
          <Typography sx={textStyle}>privacy@byneural.ai</Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Usuarios Afectados</Typography>
          <Typography sx={listItemTextStyle}>
            <strong>Usuarios de Cuentas Nuevas:</strong> Incluye a todas las
            personas que crean una cuenta en nuestras plataformas. Esto es
            necesario para acceder a servicios personalizados, soporte y
            funciones avanzadas.
          </Typography>
          <Typography sx={textStyle}>
            Esta política cubre todas las plataformas y medios a través de los
            cuales nuestros asistentes virtuales están disponibles, incluidos
            sitios web, aplicaciones móviles y otras interfaces digitales.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Datos personales que recopilamos
          </Typography>
          <Typography sx={textStyle}>
            La información recopilada generalmente incluye:
          </Typography>
          <Typography sx={listItemTextStyle}>
            <strong>Nombre y Apellidos:</strong> Para personalizar la
            experiencia y las comunicaciones.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Correo Electrónico:</strong> Utilizado como identificación
            única del usuario y para comunicaciones importantes.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Contraseña:</strong> Para proteger el acceso a la cuenta del
            usuario.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Número de Teléfono:</strong> Para mejorar la seguridad de la
            cuenta y ofrecer soporte directo.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Datos de Contacto Profesional (si aplica):</strong>{" "}
            Incluyendo el nombre de la empresa y el cargo, para usuarios
            corporativos.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Uso de la Información Personal
          </Typography>
          <Typography sx={textStyle}>
            Utilizamos la información recopilada para proporcionar y mejorar
            nuestros servicios y para comunicaciones más efectivas con los
            usuarios. Esto incluye:
          </Typography>
          <Typography sx={listItemTextStyle}>
            Establecer y mantener la cuenta del usuario en nuestro sistema.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            Proporcionar soporte al cliente y resolver problemas.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            Enviar comunicaciones relacionadas con la cuenta, como
            notificaciones de seguridad, actualizaciones de servicios y mensajes
            promocionales, según lo permitido por el usuario.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            Mejorar y personalizar la experiencia del usuario en nuestras
            plataformas.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Compartición de Información Personal
          </Typography>
          <Typography sx={textStyle}>
            Compartimos información personal con terceros solo cuando es
            necesario para la prestación de nuestros servicios, como proveedores
            de hosting o plataformas de email marketing, siempre asegurando que
            estos terceros cumplan con las normativas de protección de datos
            pertinentes.
          </Typography>
          <Typography sx={textStyle}>
            No vendemos ni compartimos tus datos con terceros.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Bases Legales para el Procesamiento de Datos Personales
          </Typography>
          <Typography sx={textStyle}>
            En byNeural, el procesamiento de datos personales se realiza
            únicamente sobre la base del consentimiento explícito del usuario.
            Este enfoque garantiza que nuestros usuarios tengan control completo
            sobre sus datos personales y comprendan cómo se utilizarán estos
            datos.
          </Typography>
          <Typography sx={textStyle}>
            Solicitamos el consentimiento explícito de los usuarios antes de
            recopilar o procesar sus datos personales. Este consentimiento se
            obtiene claramente a través de nuestras interfaces de usuario. Los
            usuarios deben activamente hacer clic en un botón o marcar una
            casilla que indique su acuerdo antes de que cualquier dato personal
            sea procesado por byNeural. Este consentimiento puede ser retirado
            por el usuario en cualquier momento, lo cual cesará cualquier
            procesamiento futuro de sus datos personales.
          </Typography>
          <Typography sx={textStyle}>
            Los usuarios tienen derecho a retirar su consentimiento en cualquier
            momento y a ejercer otros derechos con respecto a sus datos
            personales bajo el GDPR. Estos derechos incluyen acceder a sus
            datos, solicitar correcciones, y pedir la eliminación de sus datos
            personales. Para retirar el consentimiento o ejercer estos derechos,
            los usuarios pueden contactar directamente a nuestro Equipo de
            Privacidad a través de los detalles de contacto proporcionados en
            esta política.
          </Typography>
          <Typography sx={textStyle}>
            Para asegurar que los usuarios estén completamente informados,
            nuestro formulario de consentimiento incluye un enlace directo a
            esta Política de Privacidad, donde pueden revisar en detalle cómo se
            manejarán sus datos.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Derechos de los Interesados
          </Typography>
          <Typography sx={textStyle}>
            El Reglamento General de Protección de Datos (GDPR) de la Unión
            Europea, así como otras leyes de privacidad de diversos países,
            otorgan ciertos derechos a los interesados. Los derechos de los
            interesados bajo el GDPR incluyen los siguientes:
          </Typography>
          <Typography sx={listItemTextStyle}>
            <strong>Derecho a ser informado:</strong> Debes conocer cómo se
            recopilan y usan tus datos personales.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Derecho de acceso:</strong> Tienes derecho a obtener
            confirmación sobre si se están procesando tus datos personales y, en
            caso afirmativo, acceder a esos datos.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Derecho de rectificación:</strong> Puedes solicitar la
            corrección de tus datos personales si son inexactos.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Derecho de supresión:</strong> También conocido como "el
            derecho al olvido", te permite solicitar la eliminación de tus datos
            personales cuando no hay motivo legítimo para su procesamiento.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Derecho a la limitación del procesamiento:</strong> Puedes
            solicitar la limitación del procesamiento de tus datos personales.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Derecho a la portabilidad de los datos:</strong> Te permite
            recibir los datos personales que has proporcionado a una
            organización, en un formato estructurado, de uso común y lectura
            mecánica, y transmitir esos datos a otra organización.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Derecho de oposición:</strong> Puedes oponerte al
            procesamiento de tus datos personales en determinadas
            circunstancias.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>
              Derechos relacionados con la toma de decisiones automatizada y la
              elaboración de perfiles:
            </strong>{" "}
            Tienes derecho a no ser objeto de una decisión basada únicamente en
            el procesamiento automatizado, incluyendo la elaboración de
            perfiles, que produzca efectos jurídicos que te afecten
            significativamente.
          </Typography>
          <Typography sx={textStyle}>
            Si deseas confirmar que byNeural está procesando tus datos
            personales, o si quieres acceder a los datos personales que byNeural
            pueda tener sobre ti, por favor contáctanos.
          </Typography>
          <Typography sx={textStyle}>
            También puedes solicitar información sobre: el propósito del
            procesamiento; las categorías de datos personales que se procesan;
            quién más fuera de byNeural podría haber recibido los datos; cuál
            fue la fuente de los datos (si no los proporcionaste directamente a
            byNeural); y cuánto tiempo serán almacenados. Tienes derecho a
            corregir (rectificar) el registro de tus datos personales mantenido
            por byNeural si es inexacto. Puedes solicitar que byNeural borre
            esos datos o cese su procesamiento, sujeto a ciertas excepciones.
            También puedes solicitar que byNeural deje de usar tus datos para
            fines de marketing directo. En muchos países, tienes derecho a
            presentar una queja ante la autoridad de protección de datos
            correspondiente si tienes preocupaciones sobre cómo byNeural procesa
            tus datos personales. Cuando sea técnicamente factible, byNeural te
            proporcionará, a tu solicitud, tus datos personales.
          </Typography>
          <Typography sx={textStyle}>
            Se proporcionará acceso razonable a tus datos personales sin costo.
            Si el acceso no puede ser proporcionado dentro de un marco de tiempo
            razonable, byNeural te proporcionará una fecha cuando la información
            será disponible. Si por alguna razón el acceso es denegado, byNeural
            proporcionará una explicación de por qué se ha denegado el acceso.
          </Typography>
          <Typography sx={textStyle}>
            Para preguntas o quejas relativas al procesamiento de tus datos
            personales, puedes enviarnos un email a privacy@byneural.ai.
            Alternativamente, si estás ubicado en la Unión Europea, también
            puedes recurrir al Supervisor Europeo de Protección de Datos o a la
            autoridad de protección de datos de tu país.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Almacenamiento y Retención de Datos
          </Typography>
          <Typography sx={textStyle}>
            Tus datos personales son almacenados por byNeural en nuestros
            servidores y en los servidores de los servicios de gestión de bases
            de datos basados en la nube que utilizamos, ubicados en Europa.
            byNeural retiene los datos de servicio durante la duración de la
            relación comercial del cliente con byNeural y por un período de
            tiempo posterior para analizar los datos para operaciones propias de
            byNeural, y para fines históricos y de archivo asociados con
            nuestros servicios. byNeural retiene los datos de prospectos hasta
            que ya no tengan valor comercial y son eliminados de nuestros
            sistemas. Todos los datos personales que byNeural controla pueden
            ser eliminados a solicitud verificada de los sujetos de datos o sus
            agentes autorizados. Para más información sobre dónde y cuánto
            tiempo se almacenan tus datos personales, y para más información
            sobre tus derechos de supresión y portabilidad, por favor
            contáctanos en: privacy@byneural.ai.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Datos de Menores</Typography>
          <Typography sx={textStyle}>
            byNeural no intenta conscientemente solicitar ni recibir información
            de menores.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Preguntas, Preocupaciones o Quejas
          </Typography>
          <Typography sx={textStyle}>
            Si tienes preguntas, preocupaciones, quejas o deseas ejercer tus
            derechos, por favor contáctanos en:
          </Typography>
          <Typography sx={textStyle}>byNeural</Typography>
          <Typography sx={textStyle}>privacy@byneural.ai</Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
        </Stack>
      </Box>
      <Box sx={{ mt: "5rem" }}>
        <Footer />
      </Box>
    </Box>
  );
};
