import { Box, Button, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import backgroundImage from "../../Data/Assets/Images/Ecommerce/Integraciones.png";
import backgroundImageMobile from "../../Data/Assets/background_demo.png";
import laptopImage from "../../Data/Assets/demo_laptop.png";
import SmallButton from "../Buttons/SmallButton";

const IntegrationsCard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${
          isMobile ? backgroundImageMobile : backgroundImage
        })`,
        backgroundSize: "cover",
        backgroundPosition: "left",
        borderRadius: "1rem",
        padding: "0",
        width: "100%",
        height: { xs: "auto", md: "400px" },
        outline: "1px solid #E0E0E1", // Define el borde exterior
        outlineOffset: "0px", // Separa el borde del box
        fontFamily: "Inter, sans-serif",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <Grid
          item
          xs={12}
          md={7}
          lg={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              padding: { xs: "2rem 2rem", lg: "5rem 3rem" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize={isMobile ? "1.5rem" : "2rem"}
              lineHeight={1.3}
              sx={{ fontFamily: "Inter, sans-serif" }}
            >
              Integración{" "}
              <span style={{ color: colors.primary[500] }}>sin esfuerzo</span>:
              rápido, sin APIs y con{" "}
              <span style={{ color: colors.primary[500] }}>
                todas las plataformas
              </span>
            </Typography>

            <Typography
              fontSize={isMobile ? "0.875rem" : "1.25rem"}
              lineHeight={1.6}
              sx={{
                fontFamily: "Inter, sans-serif",
                marginTop: "2rem",
                marginBottom: "2rem",
                color: colors.grey[600],
              }}
            >
              Compatible con <strong> cualquier plataforma eCommerce </strong>{" "}
              como WooCommerce, Shopify, Prestashop, Magento y muchas más.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntegrationsCard;
