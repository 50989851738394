import React from "react";
import { Box, Typography, Stack, Grid, Link } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import Footer from "../Components/Footer";

export const AvisoLegal = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth } = useScreenSize();

  const mainTitleStyle = {
    mt: "3rem",
    lineHeight: "3rem",
    fontWeight: "900",
    fontSize: "3rem",
    fontFamily: "Inter",
    color: colors.grey[900],
  };

  const subtitleStyle = {
    mt: "2rem",
    fontSize: "1.25rem",
    fontFamily: "Inter",
    color: colors.primary[500],
    fontWeight: "bold",
  };

  const textStyle = {
    mt: "1rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
  };

  const listItemTextStyle = {
    mt: "1rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: 0,
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };
  const listItemTextStyleNext = {
    mt: "0rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: 0,
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };

  const listItemTextStyle2 = {
    mt: "0.5rem",
    display: "block",
    paddingLeft: "3rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: "2rem",
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };
  const listItemTextStyle2Next = {
    mt: "0rem",
    display: "block",
    paddingLeft: "3rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: "2rem",
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };

  const listItemTextStyleNumber = {
    mt: "1rem",
    display: "block",
    paddingLeft: "0", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    fontWeight: "500",
    color: colors.primary[500],
  };

  const listItemTextStyleNumberNext = {
    mt: "0rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
  };

  const determinePadding = () => {
    switch (screenSize) {
      case "md":
        return "0rem 2rem 0rem 2rem";
      case "lg":
        return "0rem 2rem 0rem 2rem";
      default:
        return "0rem 1rem 0rem 1rem";
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          height: "100%",
          maxWidth: `${pagewidth}px`,
          margin: "auto",
          padding: determinePadding(),
        }}
      >
        <Stack>
          <Typography sx={mainTitleStyle}>
            Aviso Legal de Uso de la Plataforma
          </Typography>
          <Typography sx={subtitleStyle}>
            Identificación del responsable
          </Typography>
          <Typography sx={listItemTextStyle}>
            <strong>Nombre:</strong> Israel Llagostera García
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>NIF:</strong> 46376430N
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Dirección:</strong> Carrer Esperanto 33, 08228, Barcelona,
            Cataluña, España.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Número de Teléfono:</strong> +34 608469208
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Correo electrónico:</strong> israel.llagostera@byneural.ai
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Propósito del sitio web</Typography>
          <Typography sx={textStyle}>
            El presente sitio web tiene como objetivo proporcionar una
            plataforma avanzada (byNeural), accesible para la creación,
            configuración y gestión de chatbots, especialmente diseñada para
            agencias de marketing digital, desarrollo web y consultorías
            tecnológicas. A través de nuestra plataforma, los usuarios pueden
            personalizar y gestionar asistentes virtuales que interactúan
            directamente en sus sitios web, facilitando la generación de leads,
            mejorando las conversiones y optimizando procesos internos.
          </Typography>
          <Typography sx={textStyle}>
            byNeural ofrece herramientas basadas en inteligencia artificial que
            permiten la automatización y personalización de la atención al
            cliente, garantizando el cumplimiento de normativas aplicables,
            incluyendo la protección de datos personales conforme al Reglamento
            General de Protección de Datos (GDPR). Nuestra misión es empoderar a
            las agencias y empresas con tecnología innovadora que aporte un
            valor añadido a sus operaciones, asegurando una experiencia fluida y
            eficiente para los usuarios finales.
          </Typography>
          <Typography sx={textStyle}>
            Es responsabilidad de cada usuario garantizar que los chatbots
            configurados a través de la plataforma cumplan con las normativas
            legales y regulatorias que sean aplicables en su respectiva
            jurisdicción, incluyendo aquellas relativas a la protección de datos
            y los derechos de los consumidores.
          </Typography>
          <Typography sx={textStyle}>
            Ni Israel Llagostera García ni byNeural se hacen responsables del
            contenido de las respuestas proporcionadas por los chatbots
            configurados a través de la plataforma. Los usuarios son los únicos
            responsables del diseño, contenido y funcionamiento de los chatbots
            que crean, así como de asegurar que las interacciones generadas por
            dichos asistentes virtuales cumplan con las leyes aplicables,
            incluyendo la protección de datos, la privacidad, y la legislación
            relativa a la publicidad o a la información proporcionada a los
            consumidores. byNeural no se responsabiliza por cualquier daño,
            perjuicio o acción legal derivada del mal uso o del contenido
            inadecuado generado por los chatbots.
          </Typography>

          {/* -------------------------------------------------------- */}

          <Typography sx={subtitleStyle}>Condiciones de Uso</Typography>
          <Typography sx={textStyle}>
            El acceso y uso del sitio web de byNeural y de la plataforma que
            ofrecemos están sujetos a las siguientes condiciones. Al acceder y
            utilizar nuestros servicios, el usuario acepta cumplir con estas
            condiciones.
          </Typography>
          {/* -------------------------------------------------------- */}
          <Typography sx={listItemTextStyleNumber}>
            1. Aceptación de las Condiciones
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Al utilizar este sitio web y los servicios de la plataforma, el
            usuario acepta las presentes Condiciones de Uso. Si no está de
            acuerdo con alguna de estas condiciones, debe abstenerse de utilizar
            el sitio y los servicios.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            El usuario reconoce que es su responsabilidad leer periódicamente
            estas condiciones, ya que pueden ser actualizadas o modificadas en
            cualquier momento. El uso continuado de la plataforma tras la
            publicación de modificaciones se considerará como aceptación de los
            cambios.
          </Typography>
          {/* -------------------------------------------------------- */}
          <Typography sx={listItemTextStyleNumber}>
            2. Modificación de las Condiciones
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Israel Llagostera García se reserva el derecho a modificar,
            actualizar o eliminar cualquier parte de estas Condiciones de Uso en
            cualquier momento. Las modificaciones serán efectivas desde el
            momento en que se publiquen en el sitio web o se notifiquen a los
            usuarios a través de los medios de comunicación habituales, como
            correo electrónico o notificaciones dentro de la plataforma.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Se recomienda a los usuarios revisar periódicamente las
            Condiciones de Uso para estar al tanto de posibles cambios. En caso
            de que las modificaciones afecten significativamente los derechos o
            responsabilidades del usuario, se notificará con la antelación
            razonable y, en su caso, se podrá requerir una nueva aceptación de
            los términos.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            El uso continuado de la plataforma tras la publicación o
            notificación de las modificaciones implicará la aceptación tácita de
            los nuevos términos y condiciones. Si el usuario no acepta las
            modificaciones, deberá dejar de utilizar la plataforma y sus
            servicios.
          </Typography>
          {/* -------------------------------------------------------- */}
          <Typography sx={listItemTextStyleNumber}>3. Uso Permitido</Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            El usuario se compromete a utilizar el sitio web y los servicios
            ofrecidos a través de la plataforma byNeural de manera legal y
            conforme a las presentes Condiciones de Uso, así como a la
            legislación vigente aplicable. Queda estrictamente prohibido el uso
            de la plataforma para los siguientes fines:
          </Typography>
          <Typography sx={listItemTextStyle2}>
            Realizar actividades ilícitas, fraudulentas o no autorizadas.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Crear, gestionar o utilizar chatbots con fines ilegales,
            difamatorios, abusivos, ofensivos, amenazantes o que inciten al
            odio, racismo, discriminación o violencia, o que vulneren derechos
            de terceros.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Interferir o intentar interferir con el funcionamiento correcto del
            sitio web o de la plataforma, incluyendo, pero no limitado a, la
            introducción de virus, malware o cualquier otro código dañino.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Acceder a áreas restringidas del sitio web o de la plataforma sin la
            debida autorización.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Usar los contenidos y servicios de la plataforma con fines
            comerciales sin el consentimiento previo y por escrito de byNeural.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Utilizar la plataforma para recopilar, almacenar o tratar datos
            personales de forma que infrinja el Reglamento General de Protección
            de Datos (GDPR) u otras normativas de privacidad aplicables.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          El usuario también se compromete a no utilizar los chatbots creados a través de la plataforma para recopilar información sensible sin el consentimiento explícito de los usuarios finales, ni para realizar actividades que infrinjan los derechos de propiedad intelectual de terceros.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García se reserva el derecho de suspender o cancelar el acceso del usuario a la plataforma en caso de incumplimiento de estas condiciones, así como de adoptar las medidas legales oportunas si es necesario.
          </Typography>

          {/* -------------------------------------------------------- */}

          <Typography sx={listItemTextStyleNumber}>
            4. Registro de Usuarios
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Para acceder a ciertas funcionalidades de la plataforma, los usuarios pueden estar obligados a registrarse creando una cuenta personal. El usuario se compromete a proporcionar información veraz, completa y actualizada durante el proceso de registro. Es responsabilidad del usuario mantener actualizados sus datos de contacto y demás información relevante.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          El usuario es responsable de la confidencialidad de sus credenciales de acceso (nombre de usuario y contraseña) y de todas las actividades que se realicen bajo su cuenta. En caso de detectar un uso no autorizado o cualquier brecha de seguridad, el usuario debe notificar inmediatamente a Israel Llagostera García a través de los medios de contacto proporcionados en la plataforma.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García no será responsable por los daños o pérdidas que resulten del incumplimiento de esta obligación por parte del usuario. Si Israel Llagostera García detecta actividades sospechosas, fraudulentas o en violación de estas Condiciones de Uso, se reserva el derecho de suspender o cancelar la cuenta del usuario de manera inmediata, sin perjuicio de adoptar las acciones legales oportunas.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García también se reserva el derecho de realizar verificaciones de la información proporcionada por el usuario, así como solicitar documentos adicionales que corroboren la identidad del mismo en caso de considerarlo necesario para garantizar la seguridad y legalidad de los servicios.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            5. Propiedad Intelectual
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Todos los contenidos disponibles en la plataforma, incluyendo, pero no limitado a, textos, imágenes, gráficos, logotipos, iconos, software, interfaces de usuario, diseño de la plataforma, y cualquier otro material, están protegidos por derechos de propiedad intelectual, incluidos derechos de autor, marcas registradas y patentes. Estos contenidos son propiedad exclusiva de Israel Llagostera García o de sus licenciantes, y su uso está estrictamente limitado a los términos especificados en estas Condiciones de Uso.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Los usuarios no están autorizados a modificar, copiar, reproducir, distribuir, transmitir, mostrar públicamente, ejecutar, publicar, licenciar, crear trabajos derivados, transferir o vender cualquier contenido de la plataforma sin el consentimiento previo y por escrito de Israel Llagostera García.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Además, cualquier configuración o personalización de los chatbots que los usuarios realicen a través de la plataforma sigue siendo propiedad del usuario. Sin embargo, al utilizar la plataforma, el usuario otorga a Israel Llagostera García una licencia no exclusiva, global y libre de regalías para utilizar, reproducir y mostrar dichas configuraciones en la medida en que sea necesario para la operación de la plataforma y para garantizar la prestación del servicio.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          El uso indebido de cualquier contenido de la plataforma o la violación de estos derechos de propiedad intelectual será motivo de terminación del acceso a la plataforma y podría dar lugar a acciones legales.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            6. Limitación de responsabilidad
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García no garantiza la disponibilidad, continuidad o infalibilidad del funcionamiento del sitio web y de la plataforma. En consecuencia, no se responsabiliza por cualquier daño o perjuicio, directo o indirecto, de cualquier naturaleza, que puedan derivarse de la falta de disponibilidad o continuidad del funcionamiento del sitio o de la plataforma, ni por los errores o defectos que puedan surgir en el uso de los servicios.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          El usuario entiende y acepta que utiliza la plataforma bajo su propio riesgo. Israel Llagostera García no se hace responsable de los daños, pérdidas o perjuicios que puedan resultar del uso indebido de la plataforma o de los chatbots creados y configurados por los usuarios, incluyendo, entre otros, la violación de derechos de propiedad intelectual, el incumplimiento de normativas de protección de datos o cualquier uso que infrinja leyes aplicables.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Asimismo, Israel Llagostera García no será responsable de la información proporcionada por los chatbots creados a través de la plataforma, ni del mal uso que puedan hacer los usuarios de dichos chatbots. La creación, configuración y uso de los chatbots son responsabilidad exclusiva de los usuarios.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          En ningún caso, Israel Llagostera García será responsable de pérdidas económicas, pérdidas de negocio, pérdida de reputación, ni de cualquier daño especial, indirecto o consecuente derivado del uso o imposibilidad de uso de la plataforma.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            7. Enlaces a Terceros
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          El sitio web y la plataforma pueden contener enlaces a sitios web de terceros que no son gestionados ni controlados por Israel Llagostera García. Estos enlaces se proporcionan únicamente para la conveniencia del usuario, y la inclusión de cualquier enlace no implica la aprobación ni la garantía de validez, precisión o calidad de los contenidos de dichos sitios por parte de Israel Llagostera García.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García no se hace responsable de los contenidos, políticas de privacidad, prácticas o servicios proporcionados por sitios web externos. El acceso y uso de estos sitios de terceros es bajo la responsabilidad exclusiva del usuario, quien debe revisar los términos y condiciones de dichos sitios antes de interactuar con ellos.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García no asume ninguna responsabilidad por los daños o pérdidas que puedan derivarse del uso de sitios web de terceros, incluyendo, pero no limitado a, la pérdida de datos o cualquier otro perjuicio derivado de la navegación en dichos sitios o de la confianza en los contenidos proporcionados.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            8. Terminación del Servicio
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García se reserva el derecho a suspender o terminar el acceso del usuario al sitio web y a la plataforma en cualquier momento y sin previo aviso, si considera que el usuario ha violado estas Condiciones de Uso, ha infringido alguna ley o regulación aplicable, o ha realizado un uso indebido de la plataforma, incluyendo, pero no limitado a, actividades fraudulentas, ilícitas o que comprometan la seguridad del sistema.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          En caso de terminación del servicio, el usuario no tendrá derecho a reclamaciones o compensaciones de ningún tipo. Además, Israel Llagostera García podrá eliminar o desactivar la cuenta del usuario y cualquier contenido o información asociada, sin perjuicio de las obligaciones legales que correspondan.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          El usuario también podrá solicitar la terminación de su cuenta en cualquier momento, enviando una notificación a través de los medios de contacto facilitados en la plataforma. En caso de terminación voluntaria, el usuario seguirá siendo responsable de cualquier obligación pendiente derivada de su uso de la plataforma antes de la terminación.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Israel Llagostera García no se hace responsable de la pérdida de datos u otros inconvenientes que puedan derivarse de la suspensión o terminación del servicio, recomendándose al usuario que realice copias de seguridad periódicas de los contenidos relevantes.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            9. Ley Aplicable y Jurisdicción
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Las presentes Condiciones de Uso se rigen por la legislación española. Para la resolución de cualquier controversia o conflicto que pudiera surgir en relación con el uso de la plataforma, los servicios proporcionados o la interpretación de estas condiciones, las partes se someten expresamente a la jurisdicción de los juzgados y tribunales de la ciudad de Barcelona, España, renunciando a cualquier otro fuero que pudiera corresponderles, salvo que la legislación aplicable disponga lo contrario.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
          Antes de acudir a la vía judicial, las partes intentarán resolver cualquier controversia de manera amistosa, mediante negociación directa o mediación, en un plazo razonable. Solo en caso de no alcanzar un acuerdo, las partes podrán recurrir a los tribunales competentes.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Protección de Datos Personales
          </Typography>
          <Typography sx={textStyle}>
          En cumplimiento con lo dispuesto en el Reglamento General de Protección de Datos (GDPR) y demás normativas aplicables en materia de protección de datos, Israel Llagostera García, como responsable del tratamiento de los datos personales, informa a los usuarios de que los datos recabados a través de este sitio web serán tratados de conformidad con las políticas de privacidad vigentes.
          </Typography>
          <Typography sx={textStyle}>
          Los usuarios tienen derecho a acceder, rectificar, suprimir, limitar el tratamiento, oponerse al tratamiento y a la portabilidad de sus datos personales. Para ejercer estos derechos, el usuario puede contactar directamente con Israel Llagostera García a través de la dirección de correo electrónico info@byneural.ai.
          </Typography>
          <Typography sx={textStyle}>
          La recogida y tratamiento de los datos personales se realiza con el fin de gestionar los servicios ofrecidos en la plataforma, incluyendo la creación y configuración de chatbots, así como para garantizar el cumplimiento de obligaciones contractuales y legales. Los datos personales también podrán utilizarse para enviar comunicaciones comerciales relacionadas con los servicios ofrecidos, siempre y cuando el usuario haya dado su consentimiento expreso para ello.
          </Typography>
          <Typography sx={textStyle}>
          Israel Llagostera García adopta las medidas técnicas y organizativas necesarias para garantizar la seguridad de los datos personales y evitar su alteración, pérdida, tratamiento o acceso no autorizado, de acuerdo con el estado de la tecnología y la naturaleza de los datos tratados.
          </Typography>
          <Typography sx={textStyle}>
          Los datos personales de los usuarios no serán cedidos a terceros sin su consentimiento, salvo que sea necesario para cumplir con una obligación legal o contractual, o cuando sea imprescindible para la prestación de los servicios.
          </Typography>
          <Typography sx={textStyle}>
          En caso de vulneración de los derechos del usuario en materia de protección de datos, este tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD) o la autoridad de control competente.
          </Typography>
          
          {/* --------------------------------------------------------------------------------------------------------------------- */}
        </Stack>
      </Box>
      <Box sx={{ mt: "5rem" }}>
        <Footer />
      </Box>
    </Box>
  );
};
