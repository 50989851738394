import React from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import Footer from "../../Components/Footer";
import { AccordionComponent } from "../../Components/Sections/AccordionComponent";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

import background from "../../Data/Assets/Images/Fondo.svg";
import { VideoComponent } from "../../Components/VideoComponent";
import { CarrouselLogos } from "../../Components/CarrouselLogos";
import { CustomTitle, TitleBox2 } from "../../Components/Texts/CustomTitle";
import { BenefitCard } from "../../Components/Cards/BenefitCard";

import DemoCard from "../../Components/Cards/DemoCard";
import { PlatformSection } from "../../Components/Sections/PlatformSection";
import { PartnersCard } from "../../Components/Cards/PartnersCard";
import { ContactFormL } from "../../Components/Forms/ContactFormL";
import { FeaturesGrid } from "../../Components/Sections/FeaturesGrid";
import { FeaturesGrid2 } from "../../Components/Sections/FeaturesGrid2";
import { AccordionComponentEcom } from "../../Components/Sections/AccordionComponentEcom";
import EcomHero from "../../Components/Texts/EcommerceHero";
import IntegrationsCard from "../../Components/Cards/IntegrationsCard";
import EcommerceHero from "../../Components/Texts/EcommerceHero";
import { VideoComponentEcom } from "../../Components/VideoComponentEcom";
import { TopText } from "../../Components/Texts/TopText";
import { Title } from "@mui/icons-material";
import { PrimaryText } from "../../Components/Texts/PrimaryText";
import ExtraCard from "../../Components/Cards/ExtraCard";
import ExtraImage from "../../Data/Assets/Images/Ecommerce/Conversacion.png";
import FormCard from "../../Components/Cards/FormCard";

export const EcommercePage = ({ setOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, contactFormRef } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const determinePadding = () => {
    switch (screenSize) {
      case "xs":
        return "0rem 0.75rem";
      case "sm":
        return "0rem 1.5rem";
      case "md":
        return "0rem 2rem";
      case "lg":
        return "0rem 1rem";
      default:
        return "0rem 1rem";
    }
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = "scale(1.1)";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = "scale(1)";
  };

  const { pagewidth, scrollToContact } = useScreenSize();

  const maxWidthPage = "1400px";
  const maxWidthSection = "1240px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        overflowX: "hidden",
      }}
    >
      {/* Background */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: -10,
          width: "100vw",
          zIndex: -1,
        }}
      >
        <>
          <div
            style={{
              width: "100vw",
              height: "160rem",
              backgroundImage: isMobile
                ? `url(${background})`
                : `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </>
      </div>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "0rem", width: "100%" }}
      >
        {/* Hero */}
        <Grid
          item
          xs={12}
          sx={{
            padding: determinePadding(),
            width: "100%",
            overflow: "hidden",
            marginTop: "0rem",
            justifyItems: "center",
          }}
        >
          <div
            style={{
              // margin:"0 auto",
              width: "100%",
              borderBottom: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
              borderImageSlice: 1,
              justifyItems: "center",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                maxWidth: maxWidthPage,
                width: "100%",
                borderLeft: isMobile ? "none" : "1px solid",
                borderRight: isMobile ? "none" : "1px solid",
                borderImageSource:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%)",
                borderImageSlice: 1,
              }}
            >
              <Box
                sx={{
                  padding: {
                    xs: "4rem 0rem 0rem 0rem",
                    sm: "4rem 0rem 0rem 0rem",
                    md: "4rem 2rem 0rem 2rem",
                    lg: "4rem 4rem 0rem 4rem",
                  },
                  marginBottom: isMobile ? "-1rem" : isLg ? "-0.4rem" : "0",
                }}
              >
                <EcommerceHero setOpen={setOpen} />
              </Box>
            </div>
          </div>
          <div
            style={{
              margin: "0.5rem auto 0 auto",
              width: "100%",
              height: isMobile ? "0rem" : "6rem",
              maxWidth: maxWidthPage,
              width: "100%",
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 90%)",
              borderImageSlice: 1,
            }}
          ></div>
        </Grid>

        {/* Accordion */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            p: determinePadding(),
            pb: {
              xs: "2rem",
              md: "4rem",
              width: "100%",
              justifyItems: "center",
            },
          }}
          backgroundColor="#F5F5F9"
        >
          <Stack sx={{ maxWidth: maxWidthSection, margin: "0 auto" }}>
            <CustomTitle
              toptext={"mejora los ecommerce de tus clientes"}
              title={
                <>
                  {" "}
                  Ayuda a tus clientes a{" "}
                  <span style={{ color: colors.primary[500] }}>
                    vender más{" "}
                  </span>{" "}
                  y haz crecer tu agencia
                </>
              }
              subtitle="Ofrece más valor a tus clientes: asistentes IA que mejoran la conversión, informan sobre pedidos y te dan métricas de compra en tiempo real."
            />
            <AccordionComponentEcom />
          </Stack>
        </Grid>

        {/* Sección Demo */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            width: "100%",
            justifyItems: "center",
            p: determinePadding(),
          }}
        >
          <div
            style={{
              width: "100%",
              height: isMobile ? "4rem" : "10rem",
              maxWidth: maxWidthSection,
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 90%)",
              borderImageSlice: 1,
            }}
          ></div>
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              borderTop: isMobile ? "none" : "1px solid",
              borderBottom: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
              borderImageSlice: 1,
              justifyItems: "center",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                maxWidth: isMobile ? "600px" : maxWidthSection,
                width: "100%",
                justifyItems: "center",
                borderLeft: isMobile ? "none" : "1px solid",
                borderRight: isMobile ? "none" : "1px solid",
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderImageSlice: 1,
              }}
            >
              <Box sx={{ maxWidth: maxWidthSection, width: "100%" }}>
                <IntegrationsCard />
              </Box>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: isMobile ? "4rem" : "6rem",
              maxWidth: maxWidthSection,
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 90%)",
              borderImageSlice: 1,
            }}
          ></div>
        </Grid>

        {/* Video */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          sx={{
            p: determinePadding(),
            width: "100%",
            justifyItems: "center",
            marginTop: isMobile ? "1rem" : "0rem",
          }}
        >
          <div
            style={{
              margin: "0 auto 0rem auto",
              width: "100%",
              height: isMobile ? "auto" : "auto",
              maxWidth: isMobile ? "38rem" : maxWidthSection,
              width: "100%",
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderImageSlice: 1,
              paddingBottom: isMobile ? "0rem" : "2rem",
            }}
          >
            <CustomTitle
              title={
                <>
                  Gestiona y sincroniza productos{" "}
                  <span style={{ color: colors.primary[500] }}>
                    sin complicaciones
                  </span>
                </>
              }
              subtitle="Consulta, edita y sincroniza los productos sin esfuerzo, manteniendo siempre la información actualizada."
              mTop="0rem"
            />
          </div>
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              borderTop: isMobile ? "none" : "1px solid",
              borderBottom: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)",
              borderImageSlice: 1,
              justifyItems: "center",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                marginTop: isMobile ? "2rem" : "0rem",
                maxWidth: maxWidthSection,
                width: "100%",
                justifyItems: "center",
                borderLeft: isMobile ? "none" : "1px solid",
                borderRight: isMobile ? "none" : "1px solid",
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderImageSlice: 1,
              }}
            >
              <VideoComponentEcom />
            </div>
          </div>
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              height: isMobile ? "4rem" : "10rem",
              maxWidth: maxWidthSection,
              width: "100%",
              justifyItems: "center",
              borderLeft: isMobile ? "none" : "1px solid",
              borderRight: isMobile ? "none" : "1px solid",
              borderImageSource:
                "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 90%)",
              borderImageSlice: 1,
            }}
          ></div>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            mt: { xs: "4rem", md: "0rem" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{
              maxWidth: maxWidthSection,
              width: "100%",
              height: isMobile ? "100%" : "auto",
            }}
          >
            {/* Sección de tarjetas */}
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "stretch", // Asegura que las tarjetas ocupen toda la altura
                pr: isMobile ? "0rem" : "2rem",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  height: "100%", // Se expande para ajustarse a la imagen
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "2rem", // Espacio de 2rem entre las tarjetas
                }}
              >
                <ExtraCard
                  title={
                    <>
                      Asistentes IA que{" "}
                      <span style={{ color: colors.primary[500] }}>
                        sugieren los productos
                      </span>{" "}
                      adecuados
                    </>
                  }
                  subtitle="Cada cliente es diferente. El asistente analiza la consulta y recomienda productos relevantes en segundos, mostrando imágenes, precios y características clave."
                />
                <ExtraCard
                  title={
                    <>
                      <span style={{ color: colors.primary[500] }}>
                        Atención instantánea
                      </span>{" "}
                      con respuestas detalladas
                    </>
                  }
                  subtitle="Los clientes preguntan, el asistente responde. Proporciona información técnica, compatibilidad, disponibilidad y más sin esperas."
                  otherIcon="true"
                />
              </Stack>
            </Grid>

            {/* Sección de imagen */}
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: isMobile ? "2rem" : "0rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%", // Se usa la imagen para determinar la altura de la sección
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={ExtraImage}
                  alt="IA"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "1rem",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Sección Partners */}
        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            mt: { xs: "6rem", md: "10rem" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              maxWidth: maxWidthSection,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PartnersCard setOpen={setOpen} />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            p: determinePadding(),
            mt: { xs: "4rem", md: "6rem" },
            mb: { xs: "4rem", md: "6rem" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              maxWidth: maxWidthSection,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormCard setOpen={setOpen} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};
