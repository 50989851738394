import { Box, Button, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import backgroundImage from "../../Data/Assets/Images/Ecommerce/Integraciones.png";
import laptopImage from "../../Data/Assets/demo_laptop.png";
import SmallButton from "../Buttons/SmallButton";
import { PiShoppingBag, PiTimer } from "react-icons/pi";

const ExtraCard = ({ title, subtitle, otherIcon = "false" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        position: "relative",
        backgroundPosition: "center",
        borderRadius: "1rem",
        padding: "0",
        width: "100%",
        height: "100%",
        outline: `1px solid ${colors.primary[500]}`, // Define el borde exterior
        outlineOffset: "0px", // Separa el borde del box
        fontFamily: "Inter, sans-serif",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              padding: { xs: "2rem 2rem", lg: "2rem 2rem" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {otherIcon === "true" ? (
              <PiTimer size={"2rem"} color={colors.primary[500]} />
            ) : (
              <PiShoppingBag size={"2rem"} color={colors.primary[500]} />
            )}

            <Typography
              fontWeight="bold"
              fontSize={isMobile ? "1.5rem" : "1.5rem"}
              lineHeight={1.3}
              sx={{ fontFamily: "Inter, sans-serif", mt: "1rem" }}
            >
              {title}
            </Typography>

            <Typography
              fontSize={isMobile ? "0.875rem" : "1rem"}
              lineHeight={1.6}
              sx={{
                fontFamily: "Inter, sans-serif",
                marginTop: "1rem",
                marginBottom: "1rem",
                color: colors.grey[600],
              }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExtraCard;
