import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { motion, useTime, useTransform } from "framer-motion";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

export const BenefitCard = ({ title, description }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hovered, setHovered] = useState(false);

  // Rotación del fondo multicolor
  const time = useTime();
  const rotate = useTransform(time, [0, 3000], [0, 360], { clamp: false });
  const rotatingBg = useTransform(
    rotate,
    (r) =>
      `conic-gradient(from ${r}deg, #4834DC, #00ff99, #C5BDFD, #AE00FF, #4834DC)`
  );

  // Variantes para el overlay blanco:
  // En "rest" cubre toda la tarjeta y en "hover" se retrae 1px para mostrar el borde multicolor
  const whiteOverlayVariants = {
    rest: { top: 0, left: 0, right: 0, bottom: 0, borderRadius: "8px" },
    hover: {
      top: "1px",
      left: "1px",
      right: "1px",
      bottom: "1px",
      borderRadius: "8px",
    },
  };

  return (
    <Box
      component={motion.div}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        position: "relative",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "#E0E0E1",
        boxShadow: "0 0 18px 0 rgba(72,52,220, 0.25)",
        overflow: "hidden",
      }}
    >
      {/* Fondo multicolor que se muestra al hacer hover */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: hovered ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        style={{
          position: "absolute",
          inset: 0,
          borderRadius: "8px",
          background: rotatingBg,
          zIndex: 0,
        }}
      />

      {/* Box blanco que cubre toda la tarjeta en estado "rest" y se retrae en hover */}
      <motion.div
        variants={whiteOverlayVariants}
        animate={"hover"}
        transition={{ duration: 0.3 }}
        style={{
          position: "absolute",
          backgroundColor: "white",
          zIndex: 1,
        }}
      />

      {/* Contenido de la tarjeta */}
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          p: {xs: "1.25rem 1.25rem 2rem 1.25rem", md:"1.25rem 1.25rem 1.25rem 1.25rem", lg:"1.25rem 1.25rem 3rem 1.25rem"},
          textAlign: "left",
        }}
      >
        <Stack gap={2}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "1.25rem",
              fontWeight: "600",
              lineHeight: "1.5rem",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.5rem",
              color: colors.grey[500],
            }}
          >
            {description}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
