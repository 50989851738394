import React, { useState, useRef, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Stack,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { FaRegUser, FaChevronDown } from "react-icons/fa";
import { PiX } from "react-icons/pi";

import logo1 from "../Data/Assets/LogoNew7.png";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { tokens } from "../theme";
import SmallButton from "./Buttons/SmallButton";

/*-----------------------------------------
   ESTILOS PARA LA APPBAR
------------------------------------------*/
const CustomAppBar = styled(AppBar)(({ isScrolled, isHidden }) => ({
  backgroundColor: isScrolled ? "rgba(255, 255, 255, 0.8)" : "transparent",
  backdropFilter: isScrolled ? "blur(15px)" : "none",
  position: "fixed",
  width: "100%",
  borderBottom: isScrolled ? "1px solid #EDEDED" : "none",
  transition:
    "background-color 0.3s ease, border-bottom 0.3s ease, top 0.3s ease",
  top: isHidden ? "-64px" : "0",
}));

/*-----------------------------------------
   BOTÓN LOGO
------------------------------------------*/
const LogoButton = ({ onClick, isSmallOrMd, style }) => (
  <button
    onClick={onClick}
    style={{
      background: "none",
      border: "none",
      padding: 0,
      cursor: "pointer",
      ...style,
    }}
  >
    <img
      src={logo1}
      style={{
        width: isSmallOrMd ? "8rem" : "8.5rem",
        marginTop: isSmallOrMd ? "0.5rem" : "0.25rem",
      }}
      alt="Logo"
    />
  </button>
);

/*-----------------------------------------
   BOTONES DE NAVEGACIÓN DESKTOP
------------------------------------------*/
const NavigationButtons = ({ setOpen }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const muiColors = tokens(theme.palette.mode);

  const isSmallOrMd = useMediaQuery(theme.breakpoints.down("md"));

  // Control de visibilidad del submenú
  const [showSubmenu, setShowSubmenu] = useState(false);

  // Hover en desktop
  const handleMouseEnter = () => {
    if (!isSmallOrMd) setShowSubmenu(true);
  };
  const handleMouseLeave = () => {
    if (!isSmallOrMd) setShowSubmenu(false);
  };

  // Click en móvil
  const handleClick = () => {
    if (isSmallOrMd) setShowSubmenu((prev) => !prev);
  };

  return (
    <Stack direction="row" spacing={1.5}>
      {/* SOLUCIONES */}
      <Box
        sx={{ position: "relative" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <SmallButton
          variant="transparent"
          // Texto + icono rotado si se abre el submenú
          label={
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span>Soluciones</span>
              <Box
                sx={{
                  display: "inline-block",
                  transition: "transform 0.3s ease",
                  pt: "0.25rem",
                  color: showSubmenu ? colors.primary[500] : colors.grey[400],
                  transform: showSubmenu ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <FaChevronDown />
              </Box>
            </Stack>
          }
        />

        {showSubmenu && (
          <Box
            sx={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              borderRadius: "0.5rem",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
              zIndex: 999,
              minWidth: "10rem",
              padding: "0.5rem 0",
            }}
          >
            <Box
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: "0.875rem",
                color: muiColors.grey[700],
                padding: "0.5rem 1rem",
                textAlign: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: muiColors.grey[100],
                  color: muiColors.primary[500],
                },
              }}
              onClick={() => {
                navigate("/ecommerce");
                setShowSubmenu(false);
              }}
            >
              eCommerce
            </Box>
          </Box>
        )}
      </Box>

      {/* DOCUMENTACIÓN */}
      <SmallButton
        label="Documentación"
        variant="transparent"
        onClick={() =>
          window.open("https://byneural.ai/v1/docs/plataforma/home", "_blank")
        }
      />

      {/* EQUIPO */}
      <SmallButton
        label="Equipo"
        variant="transparent"
        onClick={() => navigate("/equipo")}
      />

      {/* ACCEDER */}
      <SmallButton
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <FaRegUser size={16} />
            <span>Acceder</span>
          </Stack>
        }
        variant="secondary"
        onClick={() =>
          window.open("https://www.byneural.ai/dashboard", "_blank")
        }
      />

      {/* SOLICITA UNA DEMO */}
      <SmallButton
        label="Solicita una Demo"
        variant="primary"
        onClick={() => setOpen(true)}
      />
    </Stack>
  );
};

/*-----------------------------------------
       DRAWER PARA LA VERSIÓN MÓVIL
------------------------------------------*/
const DrawerMenu = ({ isOpen, closeMenu, scrollToContact, isMobile }) => {
  const theme = useTheme();
  const muiColors = tokens(theme.palette.mode);

  return (
    <>
      {/* Sombra de fondo */}
      <Box
        onClick={closeMenu}
        style={{
          display: isOpen ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1200,
        }}
      />
      {/* Drawer */}
      <Box
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "75%",
          maxWidth: "280px",
          height: "100vh",
          backgroundColor: "#FFFFFF",
          transform: isOpen ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1300,
          boxShadow: "-5px 0 10px rgba(0,0,0,0.1)",
          padding: "1.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {/* Logo y Botón de Cierre */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb={4}
        >
          <LogoButton isSmallOrMd={isMobile} />
          <IconButton
            onClick={closeMenu}
            sx={{
              color: muiColors.grey[500],
            }}
          >
            <PiX size={24} />
          </IconButton>
        </Stack>

        {/* Botones de navegación dentro del drawer */}
        <Stack
          spacing={2}
          alignItems={"start"}
          direction={"column"}
          height={"100%"}
        >
          <SmallButton
            label="Solicita una Demo"
            variant="primary"
            onClick={() => {
              closeMenu();
              scrollToContact();
            }}
          />
          <SmallButton
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <FaRegUser size={16} />
                <span>Acceder</span>
              </Stack>
            }
            onClick={closeMenu}
            variant="secondary"
          />
          <SmallButton
            label="Documentación"
            variant="transparent"
            onClick={() =>
              window.open(
                "https://byneural.ai/v1/docs/plataforma/home",
                "_blank"
              )
            }
          />
        </Stack>
      </Box>
    </>
  );
};

/*-----------------------------------------
       COMPONENTE PRINCIPAL TOPBAR
------------------------------------------*/
const TopBar = ({ setOpen }) => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const theme = useTheme();
  const { pagewidth, scrollToContact } = useScreenSize();
  const lastScrollY = useRef(0);

  // Para detectar si es móvil
  const isSmallOrMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = isSmallOrMd; // Alias para mayor claridad

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const currentScrollY = window.pageYOffset;
        setIsScrolled(currentScrollY > 0);
        if (!isSmallOrMd) {
          setIsHidden(currentScrollY > lastScrollY.current);
        }
        lastScrollY.current = currentScrollY;
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        if (typeof window !== "undefined") {
          window.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [isSmallOrMd]);

  // Volver arriba al cambiar de ruta
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.pathname]);

  return (
    <>
      <CustomAppBar elevation={0} isScrolled={isScrolled} isHidden={isHidden}>
        <Toolbar>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              maxWidth: pagewidth,
              margin: "auto",
            }}
          >
            {/* LOGO */}
            <LogoButton
              onClick={() => (window.location.href = "https://byneural.ai/")}
              isSmallOrMd={isMobile}
            />

            {/* Botones de navegación en desktop o icono en móvil */}
            {!isMobile ? (
              <NavigationButtons setOpen={setOpen} />
            ) : (
              <IconButton onClick={() => setIsDrawerOpen(true)}>
                <FiMenu size={24} />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </CustomAppBar>

      {/* Para compensar la AppBar fija */}
      <Toolbar />

      {/* Drawer lateral en móvil */}
      <DrawerMenu
        isOpen={isDrawerOpen}
        closeMenu={() => setIsDrawerOpen(false)}
        scrollToContact={scrollToContact}
        isMobile={isMobile}
      />
    </>
  );
};

export default TopBar;