import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { darken } from "@mui/material";

export function CustomButton({
  label,
  changesNotSaved,
  setChangesNotSaved,
  onClick,
  variant = "blue",
  customColor = null,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      setChangesNotSaved(false);
    }
  };

  return (
    <Button
      fullWidth
      onClick={handleClick}
      disabled={!changesNotSaved}
      sx={{
        height: "2.75rem",
        borderRadius: "1rem", // bordes redondeados
        fontWeight: 600,
        fontFamily: "Inter", // Asegúrate de tener la fuente "Inter" cargada en tu proyecto
        fontSize: "0.875rem", // tamaño de letra de 1rem
        lineHeight: 2.8, // altura de línea para centrar verticalmente el texto
        color: variant === "white" ? colors.primary[500] : "white", // color del texto
        backgroundColor: customColor
          ? customColor
          : variant === "blue"
          ? changesNotSaved
            ? colors.primary[500]
            : colors.primary[300]
          : variant === "white"
          ? "white"
          : "#F15D5D", // color de fondo azul del tema principal
        "&:hover": {
          backgroundColor: customColor
            ? darken(customColor, 0.2)
            : variant === "blue"
            ? colors.primary[600]
            : variant === "red"
            ? "red"
            : colors.primary[200], // color de fondo al pasar el ratón por encima, oscurecido
        },
        textTransform: "none", // evitar la transformación de texto por defecto
        textAlign: "center",
        border: variant === "white" ? `1px solid ${colors.primary[500]}` : null, // sin bordes

        // Estilos específicos para el botón deshabilitado
        "&.Mui-disabled": {
          color: changesNotSaved ? "white" : "white",
          backgroundColor: changesNotSaved
            ? colors.primary[500]
            : "colors.primary[500]",
        },
      }}
    >
      {label}
    </Button>
  );
}
